import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';

interface Props {
  isActive?: boolean;
  onClick: () => void;
  titleId: MessagesKey;
  children: React.ReactNode;
}

export const HeaderMobileAccordion = (props: Props) => {
  const { onClick, titleId, children, isActive } = props;
  return (
    <div className="border-b border-grey-300">
      <button className="inline-flex w-full items-center justify-between px-6 py-4 text-left" onClick={onClick}>
        <Typography as="span" variant="body_xl" bold id={titleId} />
        <Icon name={isActive ? 'chevronUp' : 'chevronDown'} size={16} color="grey-600" />
      </button>
      {isActive && children}
    </div>
  );
};
