import React from 'react';

import { Logo } from '@/components/_atoms/Logo';

export const HeaderLogo = () => {
  return (
    <div className={'w-[132px] laptop:w-[160px]'}>
      <Logo dark />
    </div>
  );
};
