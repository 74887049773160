import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

export const HeaderContext = createContext<{
  tabsRef: React.MutableRefObject<HTMLDivElement | null>;
  tabsAreSticky: boolean;
  setTabsAreSticky: (v: boolean) => void;
} | null>(null);

export const useHeaderCtx = () => {
  const ctx = useContext(HeaderContext);
  if (!ctx) {
    throw new Error('use header ctx inside header provider');
  }

  return ctx;
};

export const HeaderProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const [tabsAreSticky, setTabsAreSticky] = useState(false);
  const tabsRef = useRef(null);

  const headerContextValue = useMemo(
    () => ({
      tabsRef,
      tabsAreSticky,
      setTabsAreSticky,
    }),
    [tabsRef, tabsAreSticky, setTabsAreSticky],
  );

  return <HeaderContext.Provider value={headerContextValue}>{children}</HeaderContext.Provider>;
};

export const TabsWrapper = (props: PropsWithChildren) => {
  const { tabsRef, setTabsAreSticky } = useHeaderCtx();

  // Reset areSticky state on umount page
  // Issue: SNO-1258
  // Belki ➝ jeżeli wejdziemy na produkt ➝ zescrollujemy żeby pojawiła się belka ➝ damy wstecz i wejdziemy na inny produkt belka jest widoczna od razu. Nie wiem czy ma to zastosowanie do innych belek.
  useEffect(() => {
    return () => {
      setTabsAreSticky(false);
    };
  }, []);

  return <div ref={tabsRef}>{props.children}</div>;
};
