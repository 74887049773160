import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useState } from 'react';

import { createQueryClient } from './query.config';

interface Props {
  children: React.ReactNode;
}

export const QueryProvider = (props: Props) => {
  const [queryClient] = useState(() => createQueryClient());
  const { children } = props;

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} client={queryClient} />
    </QueryClientProvider>
  );
};
