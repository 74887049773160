import { useQuery } from '@tanstack/react-query';

import { request } from '@/network/Api';
import { Api } from '@/network/GeneratedApi';
import { QueryKeys } from '@/network/query.types';

export function tyreProductFetch(slug: string, query: Api.TyresProductRetrieve.RequestQuery) {
  return request.tyresProductRetrieve(slug, query);
}

export function useTyreProductQuery(slug: string, query: Api.TyresProductRetrieve.RequestQuery) {
  return useQuery({
    queryKey: [QueryKeys.tyreProduct, slug, query],
    queryFn: () => tyreProductFetch(slug, query),
    refetchOnWindowFocus: true,
  });
}

export function useTyreProductSalesQuery(sku: string) {
  return useQuery({
    queryKey: [QueryKeys.tyreProductSales, sku],
    queryFn: () => request.tyresProductSalesRetrieve(sku),
    staleTime: 60 * 60 * 1000,
  });
}
