import React from 'react';

import { Icon, Icons } from '@/components/_atoms/Icon';
import { SeasonEnum } from '@/network/GeneratedApi';
import { cn } from '@/utils/styles.utils';

interface Props {
  season: string;
  size?: number;
}

const seasonToIconMap: Record<SeasonEnum, Icons> = {
  letnie: 'seasonSummer',
  zimowe: 'seasonWinter',
  całoroczne: 'seasonAll',
};

export const ProductSeasonIcon = (props: Props) => {
  const { season, size = 24 } = props;
  const typedSeason = season as SeasonEnum;
  const icon = seasonToIconMap[typedSeason];

  return (
    <Icon
      className={cn({
        'fill-blue-500': typedSeason === 'zimowe',
        'fill-orange-500': typedSeason === 'letnie',
        '[&>path:first-child]:fill-blue-500': typedSeason === 'całoroczne',
        '[&>path:last-child]:fill-orange-500': typedSeason === 'całoroczne',
      })}
      name={icon}
      size={size}
    />
  );
};
