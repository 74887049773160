import { cn } from '@/utils/styles.utils';

interface Props {
  size?: 's' | 'm';
  checked?: boolean;
  disabled?: boolean;
}

export const RadioIndicator = (props: Props) => {
  const { size = 'm', checked = false, disabled } = props;
  return (
    <span
      className={cn(
        'inline-flex items-center justify-center rounded-full border border-grey-500 bg-white transition-colors',
        'hover:border-grey-600',
        {
          'size-4': size === 's',
          'size-5': size === 'm',
          'border-grey-1000 bg-grey-1000 hover:border-grey-800 hover:bg-grey-800': checked,
          'pointer-events-none cursor-not-allowed border-grey-400 bg-grey-300': disabled,
        },
      )}
    >
      <span
        className={cn('inline-flex scale-0 rounded-full bg-white', {
          'size-1.5': size === 's',
          'size-2': size === 'm',
          'bg-grey-500': disabled,
          'scale-100': checked,
        })}
      />
    </span>
  );
};
