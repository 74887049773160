import { SEO } from 'next-seo.config';
import Link from 'next/link';
import React from 'react';

interface Props {
  dark?: boolean;
  mono?: boolean;
}

export const Logo = (props: Props) => {
  const { dark = false, mono = false } = props;
  const colorName = dark ? 'Dark' : 'Light';
  const monoName = mono ? 'Mono' : '';
  const name = `logo${colorName}${monoName}`;
  return (
    <Link href="/" className="relative aspect-[4.1875]">
      <img src={`/brand/${name}.svg`} className="w-full" alt={SEO.defaultTitle} />
    </Link>
  );
};
