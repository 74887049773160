import { joinExisted } from '@/utils/array.utils';

interface FullNameAttributes {
  tread?: string;
  size: string;
  cargo?: string | null;
  loadIndex: string;
  speedIndex: string;
  xl?: string | null;
  runFlat?: string | null;
  homologationLong?: string | null;
  fr?: string | null;
  selfSealTechnology?: string | null;
  noiseCancellingSystem?: string | null;
}

interface ShortNameAttributes {
  size: string;
  loadIndex: string;
  speedIndex: string;
  productionYear?: string;
}

interface SizeNameAttributes {
  size: string;
  loadIndex: string;
  speedIndex: string;
  xl?: string | null;
  cargo?: string | null;
}

export const getSizeCargoName = (size: string, cargo?: string | null) => joinExisted([size, cargo]);

export const getProductFullName = (attributes: FullNameAttributes) => {
  const {
    tread,
    size,
    cargo,
    loadIndex,
    speedIndex,
    xl,
    fr,
    selfSealTechnology,
    noiseCancellingSystem,
    runFlat,
    homologationLong,
  } = attributes;
  const sizeCargo = getSizeCargoName(size, cargo);
  const loadSpeedIndex = joinExisted([loadIndex, speedIndex]);

  return joinExisted(
    [tread, sizeCargo, loadSpeedIndex, xl, fr, runFlat, homologationLong, selfSealTechnology, noiseCancellingSystem],
    ' ',
  );
};

export const getProductSizeName = (attributes: SizeNameAttributes) => {
  const { size, loadIndex, speedIndex, cargo, xl } = attributes;
  const sizeCargo = getSizeCargoName(size, cargo);
  const loadSpeedIndex = joinExisted([loadIndex, speedIndex]);
  return joinExisted([sizeCargo, loadSpeedIndex, xl], ' ');
};

export const getProductShortName = (attributes: ShortNameAttributes) => {
  const { size, loadIndex, speedIndex, productionYear } = attributes;
  const loadSpeedIndex = joinExisted([loadIndex, speedIndex]);
  return joinExisted([size, loadSpeedIndex, productionYear], ' ');
};

export const getProductTechnologiesString = (technologies: {
  runFlat?: string | null;
  selfSealTechnology?: string | null;
  noiseCancellingSystem?: string | null;
}) => {
  const { runFlat, selfSealTechnology, noiseCancellingSystem } = technologies;
  return [runFlat, selfSealTechnology, noiseCancellingSystem].filter((item) => !!item).join('/');
};

export const getProductHomologationString = (attributes: {
  homologation?: string | null;
  homologationLong?: string | null;
}) => {
  const { homologation, homologationLong } = attributes;
  if (!homologation) {
    return null;
  }
  return `${homologationLong}${homologation ? ` (${homologation})` : ''}`;
};
