import React from 'react';

import { LastSeenProduct } from '@/logic/lastSeen/lastSeen.types';

import { HeaderLastSeenItem } from './HeaderLastSeenItem';

interface Props {
  close: () => void;
  products: LastSeenProduct[];
}

export const HeaderLastSeenList = (props: Props) => {
  const { close, products } = props;

  return (
    <div className="bg-white">
      {products.map((product) => (
        <div key={product.sku} className="border-b border-grey-300 last:border-b-0">
          <HeaderLastSeenItem {...product} close={close} />
        </div>
      ))}
    </div>
  );
};
