import removeAccents from 'remove-accents';

import { SEARCH_PAGE_SLUG } from './searchParams.constants';
import { TYRE_LISTING_PAGE_TYPE } from './searchParams.types';

export const getTyreListingPageType = (slugs: string[]): TYRE_LISTING_PAGE_TYPE => {
  const [firstItem] = slugs;
  if (slugs.length === 1 && firstItem === SEARCH_PAGE_SLUG) {
    return 'search';
  }
  return 'category';
};

export const getCategoryPagePath = (slugs: string[], remove = true) => {
  const path = `/opony/${slugs.join('/')}`.toLowerCase();
  if (remove) {
    return removeAccents(path);
  }
  return path;
};
