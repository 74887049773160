import React from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { cn } from '@/utils/styles.utils';

interface Props {
  title?: string;
  text?: string;
  close: () => void;
  size?: 's' | 'm' | 'l';
  children: React.ReactNode;
  className?: string;
}

export const ModalCard = (props: Props) => {
  const { close, children, text, title, className, size = 'm' } = props;
  return (
    <div
      className={cn('relative max-w-[calc(100vw-2rem)] rounded-lg bg-white p-6', className, {
        'w-[430px]': size === 's',
        'w-[544px]': size === 'm',
        'w-[674px]': size === 'l',
      })}
    >
      <button className="absolute right-4 top-4 z-[1]" onClick={close}>
        <Icon name="close" />
      </button>
      {title ? (
        <div className="mb-6 mr-6">
          <Typography variant="body_xxl" bold className="mb-2">
            {title}
          </Typography>
          {text && <Typography>{text}</Typography>}
        </div>
      ) : null}
      {children}
    </div>
  );
};
