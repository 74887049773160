import { Checkout, CheckoutLine, CreateCheckout, CreateOrder, ListingVariant, Variant } from '@/network/GeneratedApi';
import { DataLayerPurchaseData } from '@/services/gtm/DataLayer.types';

const CURRENCY = 'PLN';

const checkoutLineMapper = (line: CheckoutLine, index = 0) => ({
  index,
  item_id: line.sku,
  item_name: line.variant.product.shop_name,
  price: line.variant.price,
  quantity: line.quantity,
  item_brand: line.variant.product.producer,
  item_category: line.variant.product.season,
  item_category2: line.variant.product.size,
  item_category3: line.variant.product.tyre_type,
  item_category4: line.variant.production_year || '',
  promotion_name: line.variant.campaigns.map((c) => c.name).join(','),
});

const listingItemMapper = (item: ListingVariant, index = 0) => ({
  index,
  item_id: item.sku,
  item_name: item.product.shop_name,
  price: item.price,
  item_brand: item.product.producer,
  item_category: item.product.season,
  item_category2: item.product.size,
  item_category3: item.product.tyre_type,
  item_category4: item.production_year || '',
  // Need to check campaigns exist(?), due to incorrect type return by swagger
  // Should be:
  // campaigns?: ListingCampaign[]
  promotion_name: item.campaigns?.map((c) => c.name).join(',') || '',
  creative_slot: item.is_promoted ? `promoted_${index}` : '',
});

export const createOrderEventMapper = (
  id: number,
  checkout: Omit<Checkout, 'token'>,
  data: CreateOrder,
): DataLayerPurchaseData => {
  const { lines, total_gross_amount } = checkout;
  return {
    transaction_id: id,
    currency: CURRENCY,
    tax: 23,
    shipping: 0,
    value: total_gross_amount,
    items: lines.map(checkoutLineMapper),
    billing_apartment_number: data.billing_apartment_number ?? '',
    billing_city: data.billing_city ?? '',
    billing_company_name: data.billing_company_name ?? '',
    billing_first_name: data.billing_first_name ?? '',
    billing_last_name: data.billing_last_name ?? '',
    billing_postal_code: data.billing_postal_code ?? '',
    billing_street: data.billing_street,
    billing_street_number: data.billing_street_number ?? '',
    billing_vat_number: data.billing_vat_number ?? '',
    phone_number: data.phone_number,
    shipping_apartment_number: data.shipping_apartment_number ?? '',
    shipping_city: data.shipping_city ?? '',
    shipping_company_name: data.shipping_company_name ?? '',
    shipping_first_name: data.shipping_first_name ?? '',
    shipping_last_name: data.shipping_last_name ?? '',
    shipping_postal_code: data.shipping_postal_code ?? '',
    shipping_street: data.shipping_street ?? '',
    shipping_street_number: data.shipping_street_number ?? '',
    user_email: data.user_email ?? '',
  };
};

export const addToCartEventMapper = (checkout: Checkout, added: CreateCheckout) => {
  const { lines } = checkout;
  const addedLine = lines.find((line) => line.sku === added.sku);
  if (addedLine) {
    return {
      currency: CURRENCY,
      value: (Number(addedLine.price) * addedLine.quantity).toFixed(2),
      items: [checkoutLineMapper(addedLine)],
    };
  }
};

export const removeFromCartEventMapper = (checkout: Checkout, removedLineSku: string) => {
  const { lines } = checkout;
  const [removedLine] = lines.filter((line) => line.sku === removedLineSku);
  return {
    currency: CURRENCY,
    value: (Number(removedLine.price) * removedLine.quantity).toFixed(2),
    items: [checkoutLineMapper(removedLine)],
  };
};

export const viewCartEventMapper = (checkout: Omit<Checkout, 'token'>) => {
  const { lines, total_gross_amount } = checkout;
  return {
    currency: CURRENCY,
    value: total_gross_amount,
    items: lines.map(checkoutLineMapper),
  };
};

export const beginCheckoutEventMapper = (checkout: Omit<Checkout, 'token'>) => {
  const { lines, total_gross_amount } = checkout;
  return {
    currency: CURRENCY,
    value: total_gross_amount,
    items: lines.map(checkoutLineMapper),
  };
};

export const viewItemListEventMapper = (items: ListingVariant[]) => {
  return {
    item_list_name: 'tyre_listing',
    currency: 'PLN',
    items: items.map(listingItemMapper),
  };
};

export const selectItemEventMapper = (item: ListingVariant, index: number) => {
  return {
    currency: 'PLN',
    item_list_name: 'tyre_listing',
    items: [listingItemMapper(item, index)],
  };
};

export const viewItemEventMapper = (item: Variant) => {
  return {
    currency: 'PLN',
    items: [listingItemMapper(item)],
  };
};

export const promotionEventMapper = (
  name: string,
  index: number,
  slot: 'listing_top_banner' | 'main_page_top_banner' | 'product_main_banner' | 'product_landing_page_banner',
) => {
  return {
    promotions: [
      {
        promotion_id: String(index),
        promotion_name: name,
        creative_slot: slot,
      },
    ],
  };
};

export const AnalyticsEventsMapper = {
  createOrder: createOrderEventMapper,
  addToCart: addToCartEventMapper,
  removeFromCard: removeFromCartEventMapper,
  viewCart: viewCartEventMapper,
  beginCheckout: beginCheckoutEventMapper,
  viewItemList: viewItemListEventMapper,
  selectItem: selectItemEventMapper,
  viewItem: viewItemEventMapper,
  promotion: promotionEventMapper,
};
