import {
  ListingVariant,
  Path,
  Variant,
  Description,
  InternalLink,
  ProductProducerAttr,
  ProductTreadAttr,
} from '@/network/GeneratedApi';

import { getProductFullName } from '../product/products.utils';

import { ListingProduct, ProducerAttributes, ProductCampaign } from './listingProductItem.types';
import { CustomSimilarVariant, mapSimilarVariant } from './similarProductItem.mappers';

export const mapProductProducerAttr = (attr: ProductProducerAttr) => {
  return {
    warranty: attr.warranty,
    logo: attr.logo || undefined,
    certificateFile: attr.certificate_file || undefined,
    warrantyDescription: attr.warranty_description,
    warrantyFile: attr.warranty_file || undefined,
    warrantyText: attr.warranty_text,
  };
};

const mapCampaign = (data: {
  name?: string;
  listing_label?: string;
  listing_label_color?: string;
  product_mobile_banner?: string | null;
  product_tablet_banner?: string | null;
  product_desktop_banner?: string | null;
  product_banner_content?: string | null;
}): ProductCampaign => {
  return {
    name: data.name as string,
    label: data.listing_label as string,
    bgColor: data.listing_label_color as string,
    mobileBanner: data.product_mobile_banner ?? undefined,
    tabletBanner: data.product_tablet_banner ?? undefined,
    desktopBanner: data.product_desktop_banner ?? undefined,
    content: data.product_banner_content ?? undefined,
  };
};

type Props =
  | {
      type: 'listing';
      item: ListingVariant;
    }
  | {
      type: 'details';
      item: Variant;
    };

export const mapVariant = ({ type, item }: Props) => {
  const {
    campaigns,
    product,
    delivery_time,
    delivery_date,
    production_year,
    price,
    sold,
    is_promoted,
    is_main,
    sku,
    old_price,
    quantity,
    has_new_label,
    has_promotion_label,
  } = item;

  const { producer, tread, season, tread_attr, images, slug, producer_attr, tyre_type, shop_name } = product;

  let breadcrumbs: Path[] | undefined;
  let warranty: string | undefined;
  let speed_index_long: string | undefined;
  let load_index_long: string | undefined;
  let variantsCount: number | undefined;
  let similarProductsCount: number | undefined;
  let ean: string | undefined;
  let description: Description | undefined;
  let code: string | undefined;
  let categoryLinks: Record<string, string> | undefined;
  let producerAttr: ProducerAttributes | undefined;
  let internalLinks: InternalLink[] | undefined;
  let metaDescription: string | undefined;
  let metaTitle: string | undefined;
  let treadAttr: ProductTreadAttr | undefined;
  let isPromotedText: string | undefined;
  let similarVariants: CustomSimilarVariant[] | undefined;
  let productAsSimilarVariant: CustomSimilarVariant | undefined;

  if (type === 'details') {
    productAsSimilarVariant = mapSimilarVariant(item);
    similarVariants = item.similar_variants.map(mapSimilarVariant);
    metaDescription = item.product.meta_description || undefined;
    metaTitle = item.product.meta_title || undefined;
    code = item.product.code || undefined;
    similarProductsCount = item.similar_products_count;
    ean = item.product.ean;
    variantsCount = item.variants_count;
    breadcrumbs = item.product.breadcrumbs;
    speed_index_long = item.product.speed_index_long;
    load_index_long = item.product.load_index_long;
    warranty = item.product.producer_attr.warranty;
    description = item.product.tread_attr.description?.sections?.length
      ? item.product.tread_attr.description
      : undefined;
    categoryLinks = item.product.category_links;
    internalLinks = item.product.internal_links as InternalLink[];
    producerAttr = mapProductProducerAttr(item.product.producer_attr);
    treadAttr = item.product.tread_attr;
  }
  if (type === 'listing') {
    isPromotedText = item.is_promoted_text || undefined;
  }
  const [image = null] = images || [];
  const euLabelImage = images?.find((image) => image.is_label);
  const mappedCampaign = (campaigns || []).map(mapCampaign);
  const [campaign = null] = mappedCampaign;

  const attributes = mapProductAttributes(product, {
    speedIndexLong: speed_index_long,
    loadIndexLong: load_index_long,
  });

  return {
    _original: item,
    sku,
    producer,
    productionYear: production_year || undefined,
    price,
    oldPrice: old_price || undefined,
    soldCount: sold || undefined,
    isPromoted: is_promoted || false,
    isPromotedText,
    isMain: is_main || false,
    hasPromotionLabel: has_promotion_label,
    hasNewLabel: has_new_label,
    deliveryTime: delivery_time,
    deliveryDate: delivery_date,
    campaign,
    campaigns: mappedCampaign,
    storeQuantity: quantity,
    variantsCount,
    similarProductsCount,
    similarVariants,
    productAsSimilarVariant,
    product: {
      metaTitle,
      metaDescription,
      shopName: shop_name,
      fullName: getProductFullName({ ...attributes, tread }),
      internalLinks,
      producerAttr,
      categoryLinks,
      code,
      ean,
      slug,
      image,
      images,
      euLabelImage,
      producer,
      tread,
      season,
      breadcrumbs,
      warranty,
      description,
      tyreType: tyre_type,
      tyreClass: producer_attr?.tyre_class || undefined,
      treadAttr,
      rating:
        tread_attr.rating_count && tread_attr.rating
          ? {
              count: tread_attr.rating_count,
              value: tread_attr.rating,
            }
          : undefined,
      attributes,
    },
  };
};

export const mapProductsToListing = (products?: ListingVariant[]) => {
  if (!products) {
    return [];
  }
  return products.map((item) =>
    mapVariant({
      type: 'listing',
      item,
    }),
  );
};

export const mapProductAttributes = (
  product: ListingProduct,
  extra?: {
    speedIndexLong?: string;
    loadIndexLong?: string;
  },
) => {
  const {
    size,
    speed_index,
    load_index,
    xl,
    label_break,
    label_fuel,
    label_sound_decibels,
    label_sound_level,
    run_flat,
    selfseal_technology,
    noice_cancelling_system,
    cargo,
    pmsf,
    homologation,
    fr,
    homologation_long,
  } = product;
  return {
    size,
    fr,
    speedIndex: speed_index,
    loadIndex: load_index,
    labelBreak: label_break,
    labelFuel: label_fuel,
    labelSoundDecibels: label_sound_decibels,
    labelSoundLevel: label_sound_level,
    runFlat: run_flat,
    selfSealTechnology: selfseal_technology,
    noiseCancellingSystem: noice_cancelling_system,
    cargo,
    pmsf,
    homologation,
    homologationLong: homologation_long,
    xl,
    ...(extra || {}),
  };
};

export type MapVariant = typeof mapVariant;
export type MapProductAttributes = typeof mapProductAttributes;
