export const normalizeBy = <ArrayType>(array: ArrayType[], key: keyof ArrayType) => {
  return array.reduce<{ ids: string[]; items: Record<string, ArrayType> }>(
    (result, item) => {
      const itemKey = item[key];
      if (typeof itemKey === 'string') {
        return {
          ids: [...result.ids, itemKey],
          items: {
            ...result.items,
            [itemKey]: item,
          },
        };
      }

      return result;
    },
    {
      ids: [],
      items: {},
    },
  );
};

export const toArray = (slug: string[] | string = []) => {
  return Array.isArray(slug) ? slug : [slug].filter((v): v is string => !!v && typeof v === 'string');
};
