import React from 'react';

import { cn } from '@/utils/styles.utils';

import { GridIem } from './GridItem';

export const Grid = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return <div className={cn('grid grid-cols-12 gap-x-6', className)} {...rest} />;
};

Grid.Item = GridIem;
