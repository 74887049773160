import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@/components/_atoms/Typography';

import { FooterListData } from '../../footer.types';

export const FooterList = (props: FooterListData) => {
  const { items, textId, text } = props;
  return (
    <nav>
      <div className="mb-6">
        <Typography variant="body_l" as="span" color="white" bold id={textId}>
          {text}
        </Typography>
      </div>

      <ul className="space-y-4">
        {items.map((item) => (
          <li
            key={item.textId || item.text}
            style={{
              lineHeight: 0,
            }}
          >
            <Link
              href={item.path}
              className="text-body-m text-text-grey-super-light inline-flex transition-all duration-300 hover:translate-x-2 hover:text-text-white"
              prefetch={false}
            >
              {item.textId ? <FormattedMessage id={item.textId} /> : item.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
