import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiErrorCodes, request } from '../Api';
import { AuthApi } from '../authApi';
import { SignInBody } from '../authApi/authApi';
import { QueryKeys } from '../query.types';

export const useLoginMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: SignInBody) => AuthApi.signIn(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.session],
      });
    },
  });
};

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => AuthApi.signOut(),
    onSuccess: () => {
      // Clear cache to not have old logged user data in cache
      queryClient.clear();
    },
  });
};

export const useRegisterMutation = () => {
  return useMutation({
    mutationFn: request.authRegisterCreate,
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.register),
    },
  });
};

export const useForgotPasswordMutation = () => {
  return useMutation({
    mutationFn: request.authPasswordResetCreate,
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.forgotPassword),
    },
  });
};

export const useResetPasswordMutation = () => {
  return useMutation({
    mutationFn: request.authPasswordResetConfirmCreate,
    meta: {
      captureException: true,
    },
  });
};
