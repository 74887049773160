import { CreateCheckout } from '@/network/GeneratedApi';
import { useCheckoutCreateMutation } from '@/network/_mutations/checkout.mutations';
import { DataLayerService } from '@/services/gtm';

import { AnalyticsEventsMapper } from '../analytics';

import { getCheckoutToken } from './checkoutToken';

export const useAddToCheckout = () => {
  const mutator = useCheckoutCreateMutation();
  const addToCheckout = async (checkoutLine: CreateCheckout) => {
    const response = await mutator.mutateAsync({
      checkoutLine,
      token: getCheckoutToken() || undefined,
    });

    const dataLayerData = AnalyticsEventsMapper.addToCart(response, checkoutLine);
    if (dataLayerData) {
      DataLayerService.addToCart(dataLayerData);
    }
  };

  return addToCheckout;
};
