import { useIntl } from 'react-intl';

import { MessagesKey } from '@/types/translations.types';

export const useTranslations = () => {
  const intl = useIntl();
  const t = (id: MessagesKey, values?: any) => intl.formatMessage({ id }, values);
  return {
    intl,
    t,
  };
};
