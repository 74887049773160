import { m } from 'framer-motion';
import React from 'react';

import { Icon, Icons } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';
import { cn } from '@/utils/styles.utils';

const textAnimation = {
  rest: {
    zIndex: -1,
    opacity: 0,
    width: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  hover: {
    opacity: 1,
    width: 'auto',
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
};

interface Props {
  icon: Icons;
  textId: MessagesKey;
  badge?: number;
  isHover?: boolean;
}

export const HeaderAction = (props: Props) => {
  const { icon, textId, badge, isHover } = props;

  return (
    <m.div
      className={cn('flex h-full items-center justify-center px-4 desktop:px-2', {
        'cursor-pointer': isHover,
      })}
      initial="rest"
      animate={isHover ? 'hover' : 'rest'}
    >
      <Icon
        name={icon}
        className={cn('fill-grey-600 transition-colors', {
          'fill-orange-500': isHover,
        })}
        badge={badge}
      />
      <div className="desktop:hidden">
        <m.span variants={textAnimation} className="flex">
          <Typography
            as="span"
            variant="body_m"
            className={cn('ml-2 truncate text-text-grey-standard transition-colors', {
              'text-text-orange': !!isHover,
            })}
            id={textId}
          />
        </m.span>
      </div>
      <div className="hidden desktop:block">
        <Typography
          variant="body_m"
          className={cn('ml-2 truncate text-text-grey-standard transition-colors', {
            'text-text-orange': !!isHover,
          })}
          id={textId}
        />
      </div>
    </m.div>
  );
};
