export { default as bag } from './bag.svg';
export { default as menuBurger } from './menuBurger.svg';
export { default as signIn } from './signIn.svg';
export { default as eye } from './eye.svg';
export { default as compare } from './compare.svg';
export { default as facebook } from './facebook.svg';
export { default as instagram } from './instagram.svg';
export { default as tick } from './tick.svg';
export { default as closeFilled } from './closeFilled.svg';
export { default as close } from './close.svg';
export { default as chevronDownFilled } from './chevronDownFilled.svg';
export { default as chevronUpFilled } from './chevronUpFilled.svg';
export { default as info } from './info.svg';
export { default as seasonAll } from './seasonAll.svg';
export { default as seasonSummer } from './seasonSummer.svg';
export { default as seasonWinter } from './seasonWinter.svg';
export { default as gas } from './gas.svg';
export { default as clouds } from './clouds.svg';
export { default as speaker } from './speaker.svg';
export { default as mountains } from './mountains.svg';
export { default as star } from './star.svg';
export { default as available } from './available.svg';
export { default as deliveryTime } from './deliveryTime.svg';
export { default as freeDelivery } from './freeDelivery.svg';
export { default as chevronUp } from './chevronUp.svg';
export { default as chevronDown } from './chevronDown.svg';
export { default as chevronLeft } from './chevronLeft.svg';
export { default as chevronRight } from './chevronRight.svg';
export { default as envelope } from './envelope.svg';
export { default as phone } from './phone.svg';
export { default as trash } from './trash.svg';
export { default as checkmarkFilled } from './checkmarkFilled.svg';
export { default as calendar } from './calendar.svg';
export { default as downloadCloud } from './downloadCloud.svg';
export { default as search } from './search.svg';
export { default as questionMark } from './questionMark.svg';
export { default as droplet } from './droplet.svg';
export { default as tyre } from './tyre.svg';
export { default as off } from './off.svg';
export { default as profile } from './profile.svg';
export { default as box } from './box.svg';
export { default as padlock } from './padlock.svg';
export { default as bullet } from './bullet.svg';
export { default as user } from './user.svg';
