import { useRef } from 'react';

export const useScrollUp = () => {
  const offset = 10;
  const scrollDirectionRef = useRef<'up' | 'down'>('down');
  const changeDirectionPositionRef = useRef(0);
  const previous = useRef(0);
  const updateScrollPosition = (scrollY: number) => {
    const scrollDirection = scrollY < previous.current ? 'up' : 'down';
    // Display header if scrolled up 10px
    const isScrolled = changeDirectionPositionRef.current - scrollY > offset;

    if (scrollDirection !== scrollDirectionRef.current) {
      changeDirectionPositionRef.current = scrollY;
      scrollDirectionRef.current = scrollDirection;
    }
    previous.current = scrollY;

    return { isScrolled };
  };
  return { updateScrollPosition };
};
