import { useMutation, useQueryClient } from '@tanstack/react-query';

import { setCheckoutToken } from '@/logic/checkout/checkoutToken';
import { QueryKeys } from '@/network/query.types';

import { ApiErrorCodes, request } from '../Api';

import { CreateCheckout } from './../GeneratedApi';

export const useCheckoutCreateMutation = () => {
  return useMutation({
    mutationFn: (data: { checkoutLine: CreateCheckout; token?: string }) =>
      request.checkoutCreate(data.checkoutLine, { token: data.token }),
    onSuccess: (checkout) => {
      setCheckoutToken(checkout.token);
    },
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.createUpdateCheckout),
    },
  });
};

export const useChangeCheckoutQuantityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { checkoutLine: CreateCheckout; token?: string }) =>
      request.checkoutCreate(data.checkoutLine, { token: data.token }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.checkout],
      });
    },
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.createUpdateCheckout),
    },
  });
};

export function useSearchCompanyByVatNumber() {
  return useMutation({
    mutationFn: (vatNumber: string) => request.coreRegonApiSearchByNipRetrieve(vatNumber),
    meta: {
      captureException: true,
    },
  });
}
