import Link from 'next/link';
import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';

interface Props {
  textId: MessagesKey;
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
  bold?: boolean;
}

export const CustomLink = (props: Props) => {
  const { textId, href, target, bold } = props;
  return (
    <Link href={href} target={target}>
      <Typography
        as="span"
        variant="body_m"
        id={textId}
        className={'relative transition-colors hover:text-text-orange'}
        underline
        bold={bold}
      />
    </Link>
  );
};
