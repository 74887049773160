import * as TooltipPrimitives from '@radix-ui/react-tooltip';
import { AnimatePresence, m } from 'framer-motion';
import { useState } from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { useBySize } from '@/hooks/useBySize';

type TooltipProps = {
  delayDuration?: number;
  disableHoverableContent?: boolean;
  side?: TooltipPrimitives.TooltipContentProps['side'];
  content: React.ReactNode | string;
  children: React.ReactNode;
  offset?: number;
  asChild?: boolean;
};

export const Tooltip = ({ delayDuration = 0, offset = 0, asChild, side, content, children }: TooltipProps) => {
  const [open, onOpenChange] = useState(false);
  const disableTooltip = useBySize({
    mobile: true,
    laptop: false,
  });
  if (disableTooltip) {
    return children;
  }

  return (
    <TooltipPrimitives.Root open={open} onOpenChange={onOpenChange} delayDuration={delayDuration}>
      <TooltipPrimitives.Trigger asChild={asChild}>{children}</TooltipPrimitives.Trigger>
      <AnimatePresence>
        {open && (
          <TooltipPrimitives.Portal forceMount>
            <TooltipPrimitives.Content
              forceMount
              sideOffset={offset}
              side={side}
              className={'relative z-[100]'}
              asChild
            >
              <m.div
                key="tooltip"
                initial="close"
                animate="open"
                exit="close"
                variants={{
                  open: { opacity: 1, y: 0 },
                  close: { opacity: 0, y: 5 },
                }}
                transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <div className="relative z-[100] flex overflow-hidden rounded-lg bg-grey-1000 p-2 shadow-bottom">
                  {typeof content === 'string' ? (
                    <Typography as="span" variant="body_s" color="white">
                      {content}
                    </Typography>
                  ) : (
                    content
                  )}
                </div>
                <TooltipPrimitives.Arrow asChild>
                  <svg width="15" height="4" viewBox="0 0 15 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.5 -2.70208e-08C11.4911 -7.66905e-07 9.5 4 7.5 4C5.5 4 3.50835 -8.80361e-07 0.499817 -1.25096e-06C-2.50872 -1.62155e-06 17.5089 7.12864e-07 14.5 -2.70208e-08Z"
                      fill="#2E2E33"
                    />
                  </svg>
                </TooltipPrimitives.Arrow>
              </m.div>
            </TooltipPrimitives.Content>
          </TooltipPrimitives.Portal>
        )}
      </AnimatePresence>
    </TooltipPrimitives.Root>
  );
};

export const TooltipProvider = TooltipPrimitives.Provider;
