import * as Portal from '@radix-ui/react-portal';
import { m } from 'framer-motion';
import Link from 'next/link';
import React, { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { ClientMounted } from '@/components/_molecules/ClientMounted';
import { EMAIL } from '@/constants/contact.constants';
import { useSession } from '@/hooks/useSession';
import { useCompareTyresStore } from '@/logic/compareTyres';
import { COMPARE_TYRES_MIN_ITEMS } from '@/logic/compareTyres/compareTyres.constatns';
import { useLastSeen } from '@/logic/lastSeen';
import { INFO_FOR_CLIENTS_STATIC_PAGE_PATH, DELIVERY_STATIC_PAGE_PATH } from '@/logic/staticPage';
import { Routes } from '@/routes/routes';

import { HeaderLastSeenList } from '../../../HeaderLastSeen';
import { HeaderMobileAccordion } from '../HeaderMobileAccordion';
import { HeaderMobileCategories } from '../HeaderMobileCategories';
import { HeaderMobileMenuButtons } from '../HeaderMobileMenuButtons';

type Props = {
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
};

export const HeaderMobileMenu = (props: Props) => {
  const { isOpen, setIsOpen } = props;
  const [showLastSeen, setShowLastSeen] = useState(false);
  const { compareTyres } = useCompareTyresStore();

  const { data: session } = useSession();
  const toggleLastSeen = () => setShowLastSeen((v) => !v);
  const lastSeen = useLastSeen();
  const close = () => setIsOpen(false);

  // Not use Radix dialog to display modal from Filter Drawer
  return (
    <ClientMounted>
      <Portal.Root>
        {/* Not remove scroll bar increase INP for mobile */}
        <RemoveScroll enabled={isOpen} removeScrollBar={false}>
          <m.aside
            className="fixed bottom-0 top-0 z-[100] w-screen bg-white"
            key="modal"
            initial="collapsed"
            animate={isOpen ? 'open' : 'collapsed'}
            variants={{
              open: { opacity: 1, transform: `translateY(0vh)`, display: 'block' },
              collapsed: {
                opacity: 0,
                transform: `translateY(100vh)`,
                transitionEnd: {
                  display: 'none',
                },
              },
            }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex h-full w-full flex-col bg-white">
              <div className="flex w-full justify-between p-6 pb-0">
                <Typography id="mobileMenu.header" as="span" variant="header_l" bold />
                <button onClick={close}>
                  <Icon name="close" />
                </button>
              </div>
              <div className="flex flex-col overflow-y-auto pt-8 [flex:1_1]">
                <HeaderMobileCategories close={close} />
                {lastSeen.shouldDisplay && (
                  <HeaderMobileAccordion
                    titleId="mobileMenu.category.lastSeen"
                    isActive={showLastSeen}
                    onClick={toggleLastSeen}
                  >
                    <HeaderLastSeenList close={close} products={lastSeen.products} />
                  </HeaderMobileAccordion>
                )}
                <div className="mt-4 flex-1">
                  {compareTyres.length > COMPARE_TYRES_MIN_ITEMS && (
                    <Link href={Routes.compare} onClick={close} className="inline-flex w-full px-6 py-3">
                      <div className="flex items-center">
                        <Typography variant="body_m" id="mobileMenu.link.compare" />
                        <div className="ml-1 flex size-4 items-center justify-center rounded-full bg-grey-1000">
                          <Typography variant="body_xxs" color="white">
                            {compareTyres.length}
                          </Typography>
                        </div>
                      </div>
                    </Link>
                  )}
                  <Link
                    href={INFO_FOR_CLIENTS_STATIC_PAGE_PATH}
                    onClick={close}
                    className={'inline-flex w-full px-6 py-3'}
                  >
                    <Typography variant="body_m" id="mobileMenu.link.infoForCustomers" />
                  </Link>
                  <Link href={DELIVERY_STATIC_PAGE_PATH} onClick={close} className={'inline-flex w-full px-6 py-3'}>
                    <Typography variant="body_m" id="mobileMenu.link.delivery" />
                  </Link>
                </div>
                <div className="p-4">
                  <Typography variant="header_l" as="p" bold id="mobileMenu.contact.header" className="mb-4" />

                  <div className="flex items-center py-4">
                    <Icon color="grey-600" name="envelope" />
                    <a href={`mailto:${EMAIL}`} className="ml-4">
                      <Typography variant="body_l" bold>
                        {EMAIL}
                      </Typography>
                    </a>
                  </div>
                </div>
                <HeaderMobileMenuButtons isAuth={!!session} close={close} />
              </div>
            </div>
          </m.aside>
        </RemoveScroll>
      </Portal.Root>
    </ClientMounted>
  );
};
