import { PropsWithChildren, useEffect, useState } from 'react';

export const ClientMounted = (props: PropsWithChildren) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted) {
    return null;
  }

  return props.children;
};
