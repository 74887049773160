import { CookieValueTypes, getCookie, removeCookies, setCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

import { addDays } from '@/utils/time.utils';

import { COMPARE_TYRES_COOKIE_EXPIRE_DAYS, COMPARE_TYRES_COOKIE_KEY } from './compareTyres.constatns';
import { CompareTyreProduct } from './compareTyres.types';

function isCompareTyreProduct(data: unknown): data is CompareTyreProduct {
  return (
    typeof data === 'object' &&
    data !== null &&
    'tread' in data &&
    'slug' in data &&
    'sku' in data &&
    'attributes' in data &&
    'isMain' in data &&
    'price' in data &&
    'producer' in data &&
    'productionYear' in data &&
    'season' in data
  );
}

function isCompareTyreProducts(data: unknown): data is CompareTyreProduct[] {
  return Array.isArray(data) && data.every(isCompareTyreProduct);
}

const parseCompareTyresCookie = (str: CookieValueTypes) => {
  if (typeof str !== 'string') {
    return null;
  }
  try {
    const value = JSON.parse(str);
    if (isCompareTyreProducts(value)) {
      return value;
    }
  } catch {
    return null;
  }
  return null;
};

export const setCompareTyresCookie = (data: CompareTyreProduct[]) => {
  setCookie(COMPARE_TYRES_COOKIE_KEY, data, {
    expires: addDays(new Date(), COMPARE_TYRES_COOKIE_EXPIRE_DAYS),
  });
};

export const removeCompareTyresCookie = () => removeCookies(COMPARE_TYRES_COOKIE_KEY);

export const getCompareTyresCookie = (context?: GetServerSidePropsContext) =>
  parseCompareTyresCookie(getCookie(COMPARE_TYRES_COOKIE_KEY, context));
