import { TyreImage } from '@/components/TyreImage';
import { Typography } from '@/components/_atoms/Typography';
import { getProductShortName } from '@/logic/product/products.utils';
import { ProductImage } from '@/network/GeneratedApi';

export interface ProductSimple {
  image: ProductImage | null;
  productionYear?: string;
  tread: string;
  producer: string;
  attributes: {
    size: string;
    loadIndex: string;
    speedIndex: string;
  };
}

interface Props extends ProductSimple {
  displayImage?: boolean;
}

export const ProductSimpleListItem = (props: Props) => {
  const { attributes, producer, tread, productionYear, image, displayImage = true } = props;
  return (
    <div className="flex items-center">
      {displayImage && (
        <div className="mr-2">
          <TyreImage
            unoptimized={image?.is_label}
            src={image?.url}
            alt={`${producer} ${tread}`}
            size="m"
            style={{
              width: 56,
              height: 56,
            }}
          />
        </div>
      )}
      <div>
        <Typography
          as="p"
          variant="body_m"
          id="compareTyres.itemName"
          values={{
            producer,
            tread,
          }}
        />
        <Typography as="p" variant="body_s" className="mt-1">
          {getProductShortName({
            ...attributes,
            productionYear,
          })}
        </Typography>
      </div>
    </div>
  );
};
