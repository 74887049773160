import React from 'react';

import { Container } from '@/components/_atoms/Container';
import { Grid } from '@/components/_atoms/Grid';
import { Hr } from '@/components/_atoms/Hr/Hr';

import { FooterBottom } from './_components/FooterBottom';
import { FooterCertificate } from './_components/FooterCertificate';
import { FooterInfo } from './_components/FooterInfo';
import { FooterLists } from './_components/FooterLists';

export const Footer = React.memo(() => {
  return (
    <div className="border-t-8 border-orange-500 bg-grey-1000">
      <Container>
        <Grid>
          <Grid.Item mobile={12} tablet={6}>
            <FooterInfo />
            <div className="tablet:hidden">
              <Hr className="bg-grey-900" />
            </div>
          </Grid.Item>
          <Grid.Item mobile={12} tablet={6}>
            <div className="py-8">
              <FooterLists />
            </div>
            <div className="mb-6 tablet:hidden">
              <FooterCertificate />
            </div>
          </Grid.Item>
        </Grid>
        <Hr className="bg-grey-900" />
        <FooterBottom />
      </Container>
    </div>
  );
});

Footer.displayName = 'Footer';
