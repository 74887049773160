// sort-imports-ignore
import '@/styles/global.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import 'overlayscrollbars/overlayscrollbars.css';

import { SpeedInsights } from '@vercel/speed-insights/next';
import { GoogleTagManager } from '@next/third-parties/google';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { DehydratedState, HydrationBoundary } from '@tanstack/react-query';
import { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import { SEO } from 'next-seo.config';
import { type AppProps } from 'next/app';
import localFont from 'next/font/local';
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import messages from '@/translations/pl.json';
import { FramerMotionLazy } from '@/components/FramerMotionLazy';
import { QueryProvider } from '@/network';
import { AppStore, AppStoreProvider } from '@/store';
import { Layout } from '@/components/Layout';
import { CustomToaster } from '@/components/CustomToaster';
import { RouterSpinner } from '@/components/RouterSpinner';
import { CompareTyres } from '@/components/CompareTyres';
import { GOOGLE_TAG_MANAGER } from '@/constants/config.constants';
import { ModalsProvider } from '@/components/_molecules/Modals';
import { HeaderProvider } from '@/providers/header.provider';

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout<T>;
};

// Woff2 not vertical align correctly on firefox and windows
// User https://transfonter.org/ to vertical align fonts
// TTF render with artifacts on windows
const lato = localFont({
  src: [
    {
      path: '../src/fonts/LatoLatin-Regular_fixed.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../src/fonts/LatoLatin-Bold_fixed.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
});

function MyApp({
  Component,
  pageProps: { ...pageProps },
}: AppPropsWithLayout<{ dehydratedState: DehydratedState; initialZustandState?: AppStore }>) {
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, user-scalable=0, initial-scale=1, maximum-scale=1" />
        <meta name="google" content="notranslate" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        {/* <link rel="preconnect" href="https://vitals.vercel-insights.com" /> */}
      </Head>
      <GoogleTagManager gtmId={GOOGLE_TAG_MANAGER ?? ''} />
      <style jsx global>{`
        :root {
          --font-lato: ${lato.style.fontFamily};
        }
      `}</style>
      <div className={lato.className}>
        <AppStoreProvider {...pageProps.initialZustandState}>
          <QueryProvider>
            <HydrationBoundary state={pageProps.dehydratedState}>
              <FramerMotionLazy>
                <IntlProvider messages={messages} locale="pl" defaultLocale="pl">
                  <ModalsProvider>
                    <TooltipProvider>
                      <HeaderProvider>
                        <DefaultSeo {...SEO} />
                        {getLayout(<Component {...pageProps} />)}
                        <CustomToaster />
                        <RouterSpinner />
                        <CompareTyres />
                      </HeaderProvider>
                    </TooltipProvider>
                  </ModalsProvider>
                </IntlProvider>
              </FramerMotionLazy>
            </HydrationBoundary>
          </QueryProvider>
        </AppStoreProvider>
      </div>
      <SpeedInsights sampleRate={0.1} />
    </>
  );
}

export default MyApp;
