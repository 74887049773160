import { AnimatePresence, m } from 'framer-motion';
import React from 'react';

import { cn } from '@/utils/styles.utils';

interface Props {
  overflow?: boolean;
  visible?: boolean;
  duration?: number;
  color?: 'orange' | 'white';
  size?: 'xxs' | 's' | 'm';
  center?: boolean;
}
export const Spinner = (props: Props) => {
  const { overflow, visible, duration = 0.3, color = 'orange', size = 'm', center = false } = props;

  const base = (
    <span
      className={cn('inline-block animate-spin rounded-full', {
        'border-yellow border-b-transparent': color === 'orange',
        'border-white border-b-transparent': color === 'white',
        'size-[14px] border-2': size === 'xxs',
        'size-6 border-2': size === 's',
        'size-12 border-[5px]': size === 'm',
      })}
    ></span>
  );

  return (
    <AnimatePresence initial={false}>
      {visible && (
        <m.div
          className={cn('flex', {
            'absolute inset-0 z-[1] flex justify-center bg-white/70 pt-[100px]': !!overflow,
            'items-center pt-0': !!center,
          })}
          key="spinner"
          initial="close"
          animate="open"
          exit="close"
          variants={{
            open: { opacity: 1 },
            close: { opacity: 0 },
          }}
          transition={{ duration }}
        >
          {base}
        </m.div>
      )}
    </AnimatePresence>
  );
};
