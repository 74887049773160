import { useShallow } from 'zustand/react/shallow';

import { useAppStore } from '@/store';

import { COMPARE_TYRES_MAX_ITEMS } from './compareTyres.constatns';
import { CompareTyreProduct } from './compareTyres.types';
import { getCompareTyresCookie, setCompareTyresCookie } from './compareTyres.utils';

export const useCompareTyresStore = () => {
  const { setCompareTyres, compareTyres } = useAppStore(
    useShallow((store) => ({
      compareTyres: store.compareTyres,
      setCompareTyres: store.setCompareTyres,
    })),
  );

  const _compareTyres = compareTyres || [];

  const set = (newCompareTyres: CompareTyreProduct[]) => {
    setCompareTyres(newCompareTyres);
    setCompareTyresCookie(newCompareTyres);
  };

  const addTyreToCompare = (data: CompareTyreProduct) => {
    // Prevent add same item
    if (_compareTyres.find((item) => item.sku === data.sku)) {
      return;
    }
    const newCompareTyres = [..._compareTyres, data].slice(-COMPARE_TYRES_MAX_ITEMS);
    set(newCompareTyres);
  };

  const removeTyreToCompare = (sku: string) => {
    const newCompareTyres = _compareTyres.filter((item) => item.sku !== sku);
    set(newCompareTyres);
  };

  const replaceTyreToCompare = (skuToRemove: string, data: CompareTyreProduct) => {
    const newCompareTyres = [..._compareTyres.filter((item) => item.sku !== skuToRemove), data];
    set(newCompareTyres);
  };

  const initializeTyreToCompare = () => {
    const _data = getCompareTyresCookie();
    setCompareTyres(_data);
  };

  return {
    compareTyres: _compareTyres,
    setTyreToCompare: set,
    addTyreToCompare,
    removeTyreToCompare,
    initializeTyreToCompare,
    replaceTyreToCompare,
  };
};
