import React from 'react';

import { Icon } from '@/components/_atoms/Icon';

interface Props {
  onRemove: () => void;
}

export const ProductRemoveIcon = (props: Props) => {
  const { onRemove } = props;

  return (
    <button className="group cursor-pointer p-2" onClick={onRemove}>
      <Icon name="trash" size={16} className="fill-grey-600 transition-colors group-hover:fill-grey-800" />
    </button>
  );
};
