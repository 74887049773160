import { Toaster } from 'react-hot-toast';

import { cn } from '@/utils/styles.utils';

export const CustomToaster = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      containerClassName={cn('[&_.toast]:text-text-center [&_.toast]:bg-grey-100 [&_.toast]:text-left')}
      toastOptions={{
        className: 'toast',
        duration: 3000,
      }}
    />
  );
};
