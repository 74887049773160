module.exports = [
  'col-span-1',
  'col-span-2',
  'col-span-3',
  'col-span-4',
  'col-span-5',
  'col-span-6',
  'col-span-7',
  'col-span-8',
  'col-span-9',
  'col-span-10',
  'col-span-11',
  'col-span-12',
  'tablet:col-span-1',
  'tablet:col-span-2',
  'tablet:col-span-3',
  'tablet:col-span-4',
  'tablet:col-span-5',
  'tablet:col-span-6',
  'tablet:col-span-7',
  'tablet:col-span-8',
  'tablet:col-span-9',
  'tablet:col-span-10',
  'tablet:col-span-11',
  'tablet:col-span-12',
  'laptop:col-span-1',
  'laptop:col-span-2',
  'laptop:col-span-3',
  'laptop:col-span-4',
  'laptop:col-span-5',
  'laptop:col-span-6',
  'laptop:col-span-7',
  'laptop:col-span-8',
  'laptop:col-span-9',
  'laptop:col-span-10',
  'laptop:col-span-11',
  'laptop:col-span-12',
  'desktop:col-span-1',
  'desktop:col-span-2',
  'desktop:col-span-3',
  'desktop:col-span-4',
  'desktop:col-span-5',
  'desktop:col-span-6',
  'desktop:col-span-7',
  'desktop:col-span-8',
  'desktop:col-span-9',
  'desktop:col-span-10',
  'desktop:col-span-11',
  'desktop:col-span-12',
  'col-start-1',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
  'col-start-8',
  'col-start-9',
  'col-start-10',
  'col-start-11',
  'col-start-12',
  'tablet:col-start-1',
  'tablet:col-start-2',
  'tablet:col-start-3',
  'tablet:col-start-4',
  'tablet:col-start-5',
  'tablet:col-start-6',
  'tablet:col-start-7',
  'tablet:col-start-8',
  'tablet:col-start-9',
  'tablet:col-start-10',
  'tablet:col-start-11',
  'tablet:col-start-12',
  'laptop:col-start-1',
  'laptop:col-start-2',
  'laptop:col-start-3',
  'laptop:col-start-4',
  'laptop:col-start-5',
  'laptop:col-start-6',
  'laptop:col-start-7',
  'laptop:col-start-8',
  'laptop:col-start-9',
  'laptop:col-start-10',
  'laptop:col-start-11',
  'laptop:col-start-12',
  'desktop:col-start-1',
  'desktop:col-start-2',
  'desktop:col-start-3',
  'desktop:col-start-4',
  'desktop:col-start-5',
  'desktop:col-start-6',
  'desktop:col-start-7',
  'desktop:col-start-8',
  'desktop:col-start-9',
  'desktop:col-start-10',
  'desktop:col-start-11',
  'desktop:col-start-12',
];
