import { ABOUT_LIST, CATEGORIES_LIST, FOR_CLIENTS_LIST, LEGAL_LIST, PRODUCERS_LIST } from '../../footer.data';
import { FooterList } from '../FooterList/FooterList';

export const FooterLists = () => {
  return (
    <div className="grid grid-cols-2 gap-x-6 gap-y-8 desktop:grid-cols-4 desktop:gap-y-10">
      <FooterList {...FOR_CLIENTS_LIST} />
      <FooterList {...ABOUT_LIST} />
      <FooterList {...CATEGORIES_LIST} />
      <FooterList {...PRODUCERS_LIST} />
      <div className="tablet:hidden">
        <FooterList {...LEGAL_LIST} />
      </div>
    </div>
  );
};
