import Link from 'next/link';
import React from 'react';

import { getTyreDetailsUrl } from '@/utils/router.utils';

interface Props {
  slug: string;
  sku: string;
  isMain: boolean;
  children: React.ReactNode;
  anchor?: string;
  onClick?: () => void;
  className?: string;
}

export const ProductLink = (props: Props) => {
  const { slug, sku, isMain, children, anchor, onClick, className } = props;
  return (
    <Link
      rel={!isMain ? 'nofollow' : undefined}
      className={className}
      href={{
        pathname: getTyreDetailsUrl(slug),
        query: { ...(isMain ? {} : { sup: sku }), ...(anchor ? { anchor } : {}) },
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
