import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { LinkButton } from '@/components/_molecules/Button/LinkButton';
import { CustomLink } from '@/components/_molecules/CustomLink';
import { DropdownWrapper } from '@/components/_molecules/DropdownWrapper/DropdownWrapper';
import { MenuItem, MenuItemProps } from '@/components/_molecules/MenuItem';
import { useSession } from '@/hooks/useSession';
import { useTranslations } from '@/hooks/useTranslations';
import { useLogout } from '@/logic/auth/useLogout';
import { Routes } from '@/routes/routes';

import { HeaderAction } from '../HeaderAction/HeaderAction';

export const HeaderAccount = () => {
  const router = useRouter();
  const { t } = useTranslations();
  const [isHover, setIsHover] = useState(false);
  const { data: session } = useSession();
  const logout = useLogout();

  const close = useCallback(() => setIsHover(false), []);
  const open = () => setIsHover(true);

  const items: MenuItemProps[] = [
    {
      icon: 'profile',
      textId: 'header.action.account.profile',
      onClick: () => {
        close();
        router.push(Routes.account);
      },
    },
  ];
  if (session) {
    items.push({
      icon: 'box',
      textId: 'header.action.account.orders',
      onClick: () => {
        close();
        router.push(Routes.accountOrders);
      },
    });
    items.push({
      textId: 'header.action.account.logout',
      icon: 'off',
      highlight: true,
      onClick: () => {
        close();
        logout();
      },
    });
  }

  return (
    <div className="relative h-full" onMouseEnter={open} onMouseLeave={close}>
      <Link href={Routes.account} prefetch={false}>
        <HeaderAction icon="user" textId="header.actions.account" isHover={isHover} />
      </Link>
      <DropdownWrapper isOpen={isHover} className="absolute left-[calc(-125px+50%)] top-[calc(100%-4px)] w-[250px]">
        <div className="my-2">
          {!session && (
            <div className="border-b border-solid border-grey-300 px-4 pb-6 pt-2">
              <LinkButton
                href={Routes.login}
                variant="primary"
                textId="header.action.account.signIn"
                className="w-full"
              />
              <Typography className="mt-3 text-center">
                {t('header.action.account.noAccount', {
                  link: <CustomLink href={Routes.register} textId="header.action.account.createAccount" bold />,
                })}
              </Typography>
            </div>
          )}
          <div className="divide-y divide-grey-300">
            {items.map((item) => (
              <MenuItem key={item.textId} {...item} />
            ))}
          </div>
        </div>
      </DropdownWrapper>
    </div>
  );
};
