import { Button } from '@/components/_molecules/Button';
import { useModals } from '@/components/_molecules/Modals';
import { useCompareTyresStore, type CompareTyreProduct } from '@/logic/compareTyres';
import { COMPARE_TYRES_MAX_ITEMS } from '@/logic/compareTyres/compareTyres.constatns';

interface Props {
  data: CompareTyreProduct;
  mobile?: boolean;
  className?: string;
}

export const ProductCompareButton = ({ data, mobile = false, className }: Props) => {
  const { openCompareModal } = useModals();
  const { addTyreToCompare, compareTyres, removeTyreToCompare } = useCompareTyresStore();
  const isSelected = !!compareTyres.find((item) => item.sku === data.sku);
  const onClick = () => {
    if (isSelected) {
      return removeTyreToCompare(data.sku);
    }
    if (compareTyres.length === COMPARE_TYRES_MAX_ITEMS) {
      return openCompareModal({
        product: data,
      });
    }
    addTyreToCompare(data);
  };

  return (
    <Button
      textId={isSelected ? 'product.removeCompare' : 'product.compare'}
      variant={isSelected ? 'secondary' : 'subtle'}
      size={mobile ? 'm' : 'xs'}
      onClick={onClick}
      className={className}
    />
  );
};
