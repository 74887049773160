import { ParsedUrlQuery } from 'querystring';

import { request } from '@/network/Api';
import { CategoryDetailTypeEnum } from '@/network/GeneratedApi';

import { getTyresListingFiltersPayload, getTyresListingPayload } from './tyresListing.utils';

export async function tyresListingFetch(urlQuery: ParsedUrlQuery, signal?: AbortSignal) {
  const { type, searchParams, page } = getTyresListingPayload(urlQuery);

  if (type === 'category') {
    const data = await request.tyresCategoryRetrieve(searchParams, { signal });

    // redirect to category if not items in request and page > 1
    const redirectOnEmptyList = data?.products.results.length === 0 && searchParams.offset > 0;
    // redirect if first page exist in url. ?page=1. Remove that search param
    const redirectOnFirstPage = page === 1 || Number.isNaN(page);

    const shouldRedirect = redirectOnEmptyList || redirectOnFirstPage;
    const redirect = shouldRedirect ? searchParams.path : null;
    return {
      ...data,
      redirect,
      pageType: 'tyre_listing' as CategoryDetailTypeEnum,
      type,
    };
  }

  if (type === 'search') {
    const products = await request.tyresSearchList(searchParams, { signal });
    return {
      pageType: 'tyre_listing' as CategoryDetailTypeEnum,
      redirect: null,
      type,
      products,
    };
  }
  throw new Error(`incorrect type ${type}`);
}

export async function tyresListingFiltersFetch(urlQuery: ParsedUrlQuery, signal?: AbortSignal) {
  const { pageType, filters, path } = getTyresListingFiltersPayload(urlQuery);

  if (pageType === 'category') {
    return request.tyreSearchBarCategoryFiltersList(
      {
        path,
      },
      { signal },
    );
  }

  if (pageType === 'search') {
    return request.tyreSearchBarFiltersList(filters, { signal });
  }
  throw new Error(`incorrect type ${pageType}`);
}

export type TyresListingFetch = Awaited<ReturnType<typeof tyresListingFetch>>;
