import type { Config } from 'tailwindcss';

import safelist from './tailwind.safelist';

const config = {
  content: ['./src/**/*.tsx', './pages/**/*.tsx'],
  safelist: safelist,
  theme: {
    fontSize: {
      'header-xxxl': ['4rem', '4.5rem'],
      'header-xxl': ['3rem', '3.875rem'],
      'header-price-xl': ['2.25rem', '2.5rem'],
      'header-xl': ['2rem', '2.5rem'],
      'header-l': ['1.5rem', '2rem'],
      'body-xxl': ['1.25rem', '1.75rem'],
      'body-xl': ['1.125rem', '1.5rem'],
      'body-l': ['1rem', '1.375rem'],
      'body-m': ['0.875rem', '1.25rem'],
      'body-s': ['0.75rem', '1rem'],
      'body-xs': ['0.6875rem', '0.875rem'],
      'body-xxs': ['0.625rem', '0.875rem'],
    },
    screens: {
      mobile: '360px',
      tablet: '768px',
      laptop: '1024px',
      desktop: '1238px',
    },
    colors: {
      inherit: 'inherit',
      initial: 'initial',
      white: '#fff',
      black: '#000',
      yellow: 'var(--color-yellow)',
      transparent: 'transparent',
      'grey-1000': 'var(--color-grey-1000)',
      'grey-900': 'var(--color-grey-900)',
      'grey-800': 'var(--color-grey-800)',
      'grey-700': 'var(--color-grey-700)',
      'grey-600': 'var(--color-grey-600)',
      'grey-500': 'var(--color-grey-500)',
      'grey-400': 'var(--color-grey-400)',
      'grey-300': 'var(--color-grey-300)',
      'grey-200': 'var(--color-grey-200)',
      'grey-100': 'var(--color-grey-100)',
      'grey-50': 'var(--color-grey-50)',
      'blue-700': 'var(--color-blue-700)',
      'blue-500': 'var(--color-blue-500)',
      'blue-50': 'var(--color-blue-50)',
      'orange-600': 'var(--color-orange-600)',
      'orange-500': 'var(--color-orange-500)',
      'orange-400': 'var(--color-orange-400)',
      'orange-200': 'var(--color-orange-200)',
      'green-700': 'var(--color-green-700)',
      'green-500': 'var(--color-green-500)',
      'green-400': 'var(--color-green-400)',
      'red-600': 'var(--color-red-600)',
      'red-500': 'var(--color-red-500)',
      'red-400': 'var(--color-red-400)',
      'red-100': 'var(--color-red-100)',
      'beige-900': 'var(--color-beige-900)',
      'beige-100': 'var(--color-beige-100)',
      'beige-50': 'var(--color-beige-50)',
      'text-grey-dark': 'var(--color-text-grey-dark)',
      'text-grey-standard': 'var(--color-text-grey-standard)',
      'text-grey-semi': 'var(--color-text-grey-semi)',
      'text-grey-light': 'var(--color-text-grey-light)',
      'text-grey-super-light': 'var(--color-text-grey-super-light)',
      'text-white': 'var(--color-text-white)',
      'text-red': 'var(--color-text-red)',
      'text-blue': 'var(--color-text-blue)',
      'text-orange': 'var(--color-text-orange)',
      'text-orange-dark': 'var(--color-text-orange-dark)',
      'text-green': 'var(--color-text-green)',
    },
    extend: {
      boxShadow: {
        bottom: '0px 4px 32px rgba(46, 46, 51, 0.08)',
        top: '0px -4px 32px rgba(46, 46, 51, 0.08)',
        saleBox: '0px 0px 0px 3px var(--color-grey-200)',
        stickyMenu: '0px 1px 4px 0px rgb(0 0 0 / 8%)',
      },
      letterSpacing: {
        'caption-s': 'calc(0.2 * 0.875rem)',
      },
    },
  },
  plugins: [],
} satisfies Config;

export default config;
