// TODO: add all pages
export const Routes = {
  login: '/logowanie',
  register: '/rejestracja',
  compare: '/porownywarka',
  account: '/konto',
  accountOrders: '/konto/zamowienia',
  accountPassword: '/konto/zmien-haslo',
  accountOrderDetails: (orderId: string) => `/konto/zamowienia/${orderId}`,
};
