import { getCookie, removeCookies, setCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

import { addYears } from '@/utils/time.utils';

const CHECKOUT_TOKEN_KEY = 'CHECKOUT_TOKEN';

export const setCheckoutToken = (token: string | null) => {
  if (token) {
    setCookie(CHECKOUT_TOKEN_KEY, token, {
      expires: addYears(new Date(), 1),
    });
  } else {
    removeCookies(CHECKOUT_TOKEN_KEY);
  }
};

export const getCheckoutToken = (context?: GetServerSidePropsContext) => {
  const cookieValue = getCookie(CHECKOUT_TOKEN_KEY, context);
  const checkoutToken = cookieValue;
  if (typeof checkoutToken === 'string') {
    return checkoutToken;
  }
  return null;
};
