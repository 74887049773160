import * as RadioGroupPrimitives from '@radix-ui/react-radio-group';

import { RadioIndicator } from '@/components/_atoms/RadioIndicator';
import { type CompareTyreProduct } from '@/logic/compareTyres';

import { ProductSimpleListItem } from '../../../../_molecules/ProductSimpleListItem';

interface Props {
  options: CompareTyreProduct[];
  value?: string;
  onChange: (sku: string) => void;
}

export const ProductCompareSelect = (props: Props) => {
  const { options, value, onChange } = props;
  return (
    <RadioGroupPrimitives.Root value={value} onValueChange={onChange}>
      <div className="space-y-4">
        {options.map((option) => (
          <div key={option.sku} className="flex items-center">
            <RadioGroupPrimitives.Item value={option.sku} id={option.sku}>
              <RadioIndicator size={'m'} checked={option.sku === value} />
            </RadioGroupPrimitives.Item>
            <label htmlFor={option.sku} className="flex-1 cursor-pointer pl-4">
              <ProductSimpleListItem {...option} displayImage={false} />
            </label>
          </div>
        ))}
      </div>
    </RadioGroupPrimitives.Root>
  );
};
