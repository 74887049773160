import { useTranslations } from '@/hooks/useTranslations';

import { Button } from '../Button';
import { ModalCard } from '../ModalCard';

import { ModalInfoData } from './Modals';

type ModalConfirmProps = {
  modal: ModalInfoData;
  close: () => void;
};

export const ModalInfo = ({ modal, close }: ModalConfirmProps) => {
  const { t } = useTranslations();

  const onConfirm = async () => {
    modal.onConfirm?.();
    close();
  };

  return (
    <>
      <ModalCard title={modal.title} text={modal.description} close={close} size="s">
        <Button
          variant="subtle"
          text={modal.label ?? t('commons.infoModal.buttonLabel')}
          onClick={onConfirm}
          {...(modal.buttonProps ?? {})}
        />
      </ModalCard>
    </>
  );
};
