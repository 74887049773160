import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ParsedUrlQuery } from 'querystring';

import { mapProductsToListing } from '@/logic/listingProductItem/listingProductItem.mappers';
import { TyresListingFetch, tyresListingFetch } from '@/logic/tyresListing';
import { tyresListingFiltersFetch } from '@/logic/tyresListing/tyresListing.service';
import { QueryKeys } from '@/network/query.types';

import { request } from '../Api';

// Tyre page

const selectTyreListing = (data: TyresListingFetch) => {
  const category = data?.type === 'category' ? data.category : null;
  const _similarProducts = data?.type === 'category' ? data.similar_products : undefined;
  const similarProducts = mapProductsToListing(_similarProducts);
  const similarProductsLabel = data?.type === 'category' ? data.similar_products_label : undefined;
  const products = mapProductsToListing(data.products?.results) || [];
  return {
    category,
    similarProducts,
    similarProductsLabel,
    products,
    _originalProducts: data.products.results,
    count: data.products.count,
  };
};

export const tyreListingQueryOptions = (query: ParsedUrlQuery) => {
  return {
    queryKey: [QueryKeys.tyresListingPage, query],
    queryFn: ({ signal }: { signal: AbortSignal }) => tyresListingFetch(query, signal),
  };
};

export function useTyreListingPageQuery(query: ParsedUrlQuery, enabled = true) {
  return useQuery({
    ...tyreListingQueryOptions(query),
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData,
    enabled,
    select: selectTyreListing,
  });
}

// Banners

export const tyreListingBannersQueryOptions = () => {
  return {
    queryKey: [QueryKeys.tyresListingBanners],
    queryFn: request.tyresListingBannersList,
  };
};

export function useTyreListingBanners() {
  return useQuery({
    ...tyreListingBannersQueryOptions(),
    staleTime: Infinity,
  });
}

// Filters

export const tyreListingFiltersQueryOptions = (query: ParsedUrlQuery) => {
  // Not pas page, ordering to query key
  const { page, ordering, ...restQuery } = query;
  return {
    queryKey: [QueryKeys.tyreSearchBarFilters, restQuery],
    queryFn: ({ signal }: { signal: AbortSignal }) => tyresListingFiltersFetch(restQuery, signal),
  };
};

export function useTyreListingFiltersQuery(query: ParsedUrlQuery) {
  return useQuery({
    ...tyreListingFiltersQueryOptions(query),
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
  });
}
