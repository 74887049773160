import { useQuery } from '@tanstack/react-query';

import { request } from '@/network/Api';
import { QueryKeys } from '@/network/query.types';

export function fetchTyresCars() {
  return request.tyresCarsRetrieve();
}

export function fetchTyresCarProducer(slug: string) {
  return request.tyresCarsRetrieve2(slug);
}

export function fetchTyresCarModel(producerSlug: string, modelSlug: string) {
  return request.tyresCarsRetrieve3(producerSlug, modelSlug);
}

export function useTyresCarsQuery() {
  return useQuery({
    queryKey: [QueryKeys.tyresCars],
    queryFn: fetchTyresCars,
  });
}

export function useTyresCarProducerQuery(slug: string) {
  return useQuery({
    queryKey: [QueryKeys.tyresCarProducer, slug],
    queryFn: () => fetchTyresCarProducer(slug),
  });
}

export function useTyresCarModelQuery(producerSlug: string, modelSlug: string) {
  return useQuery({
    queryKey: [QueryKeys.tyresCarModel, producerSlug, modelSlug],
    queryFn: () => fetchTyresCarModel(producerSlug, modelSlug),
  });
}
