import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

import config from '../../tailwind.config';

const twMergeConfig = extendTailwindMerge({
  override: {
    classGroups: {
      'font-size': Object.keys(config.theme?.fontSize || {}).map((key) => `text-${key}`),
      'text-color': Object.keys(config.theme?.colors || {}).map((key) => `text-${key}`),
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMergeConfig(clsx(inputs));
}
