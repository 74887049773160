import React from 'react';

import { HeaderBottom } from './components/HeaderBottom';
import { HeaderTop } from './components/HeaderTop';

export const Header = React.memo(() => {
  return (
    <>
      <div className="hidden laptop:block">
        <HeaderTop />
      </div>
      <HeaderBottom />
    </>
  );
});

Header.displayName = 'Header';
