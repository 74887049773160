import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container } from '@/components/_atoms/Container';
import { DELIVERY_STATIC_PAGE_PATH, INFO_FOR_CLIENTS_STATIC_PAGE_PATH } from '@/logic/staticPage';

import { HeaderContact } from '../HeaderContact';

export const HeaderTop = () => {
  return (
    <div className="bg-grey-1000">
      <Container>
        <div className={'flex h-8 items-center justify-between'}>
          <ul className="inline-flex h-full">
            <li className="mr-12 inline-flex h-full items-center">
              <Link
                href={DELIVERY_STATIC_PAGE_PATH}
                className="text-body-xs text-text-grey-super-light hover:text-text-white"
              >
                <FormattedMessage id="headerTop.delivery" />
              </Link>
              <span className="ml-2 text-body-xs font-bold text-text-orange">
                <FormattedMessage id="headerTop.delivery.free" />
              </span>
            </li>
            <li className="mr-12 inline-flex h-full items-center">
              <Link
                href={INFO_FOR_CLIENTS_STATIC_PAGE_PATH}
                className="text-body-xs text-text-grey-super-light hover:text-text-white"
              >
                <FormattedMessage id="headerTop.infoForCustomer" />
              </Link>
            </li>
            <li className="mr-12 inline-flex h-full items-center">
              <Link href="/blog" className="text-body-xs text-text-grey-super-light hover:text-text-white">
                <FormattedMessage id="headerTop.blog" />
              </Link>
            </li>
          </ul>
          <HeaderContact />
        </div>
      </Container>
    </div>
  );
};
