import { m, AnimatePresence } from 'framer-motion';
import React from 'react';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
}

export const DropdownWrapper = (props: Props) => {
  const { isOpen, className, children } = props;
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <div className={className}>
          <m.div
            key="list"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, transform: 'translateY(0px)' },
              collapsed: { opacity: 0, transform: 'translateY(5px)' },
            }}
            transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="h-inherit w-full overflow-hidden rounded-lg border border-grey-300 bg-white shadow-bottom">
              {children}
            </div>
          </m.div>
        </div>
      )}
    </AnimatePresence>
  );
};
