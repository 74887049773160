import React from 'react';

import { Icon, Icons } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';
import { cn } from '@/utils/styles.utils';

export interface MenuItemProps {
  textId: MessagesKey;
  icon: Icons;
  highlight?: boolean;
  onClick?: () => void;
  borderRadius?: boolean;
  active?: boolean;
}

export const MenuItem = (props: MenuItemProps) => {
  const { textId, icon, onClick, highlight, borderRadius = false, active = false } = props;
  return (
    <button
      onClick={onClick}
      className={cn('group flex h-11 w-full items-center bg-transparent px-4 transition-colors hover:bg-grey-100', {
        'rounded-lg': borderRadius,
        'bg-grey-100': active,
      })}
    >
      <Icon
        name={icon}
        size={16}
        className={cn('fill-grey-600 transition-colors', {
          'fill-grey-1000': active,
          'fill-red-400': highlight,
          'group-hover:fill-grey-800': !active && !highlight,
        })}
      />
      <Typography
        as="span"
        variant="body_m"
        bold
        id={textId}
        className={cn('pl-3 text-text-grey-standard', {
          'text-text-red': highlight,
          'text-text-grey-dark': active,
        })}
      />
    </button>
  );
};
