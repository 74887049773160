import { SimilarVariant } from '@/network/GeneratedApi';

export type CustomSimilarVariant = ReturnType<typeof mapSimilarVariant>;

export const mapSimilarVariant = (item: SimilarVariant) => ({
  producer: item.product.producer,
  productionYear: item.production_year || undefined,
  sku: item.sku,
  isMain: item.is_main || false,
  tread: item.product.tread,
  slug: item.product.slug,
  attributes: {
    size: item.product.size,
    loadIndex: item.product.load_index,
    speedIndex: item.product.speed_index,
  },
  image: item.product.images[0] || null,
  price: item.price,
  storeQuantity: 0,
  product: item.product,
});
