import { PropsWithChildren, createContext, startTransition, useCallback, useContext, useMemo, useState } from 'react';

import { ProductCompareModal } from '@/components/ProductsListItem/components/ProductCompare';
import { CompareTyreProduct } from '@/logic/compareTyres';

import { ButtonProps } from '../Button';
import { Modal, ModalContent } from '../Modal/Modal';

import { ModalConfirm } from './ModalConfirm';
import { ModalInfo } from './ModalInfo';

export type ModalConfirmData = {
  type: 'confirm';
  title: string;
  description: string;
  onCancel?: () => void;
  onConfirm?: () => void | Promise<void>;
  labels?: {
    confirm?: string;
    cancel?: string;
  };
  buttonsProps?: {
    confirm?: Pick<ButtonProps, 'color' | 'variant'>;
    cancel?: Pick<ButtonProps, 'color' | 'variant'>;
  };
};

export type ModalInfoData = {
  type: 'info';
  title: string;
  description: string;
  label?: string;
  buttonProps?: Pick<ButtonProps, 'color' | 'variant'>;
  onConfirm?: () => void;
};

export type ModalProductCompareData = {
  type: 'compare';
  product: CompareTyreProduct;
};

// In future put here types for more generic modals
type ModalData = ModalConfirmData | ModalInfoData | ModalProductCompareData;

export const ModalsContext = createContext<{ setModal: (modal: ModalData | null) => void } | undefined>(undefined);

export const useModalsContext = () => {
  return useContext(ModalsContext);
};

export const useModals = () => {
  const ctx = useModalsContext();
  if (!ctx) {
    throw new Error('use inside modals provider');
  }

  const { setModal } = ctx;

  const openConfirmModal = useCallback(
    (data: Omit<ModalConfirmData, 'type'>) => {
      setModal({
        type: 'confirm',
        ...data,
      });
    },
    [setModal],
  );

  const openInfoModal = useCallback(
    (data: Omit<ModalInfoData, 'type'>) => {
      setModal({
        type: 'info',
        ...data,
      });
    },
    [setModal],
  );

  const openCompareModal = useCallback(
    (data: Omit<ModalProductCompareData, 'type'>) => {
      setModal({
        type: 'compare',
        ...data,
      });
    },
    [setModal],
  );

  return {
    openConfirmModal,
    openInfoModal,
    openCompareModal,
  };
};

export const ModalsProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const [modal, setModal] = useState<ModalData | null>(null);
  const isOpen = !!modal;
  const close = () =>
    startTransition(() => {
      setModal(null);
    });
  const onOpenChange = (value: boolean) => {
    if (!value) {
      close();
    }
  };

  const value = useMemo(
    () => ({
      setModal: (data: ModalData | null) => startTransition(() => setModal(data)),
    }),
    [setModal],
  );

  return (
    <ModalsContext.Provider value={value}>
      {children}
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {modal?.type === 'confirm' && <ModalConfirm modal={modal} close={close} />}
          {modal?.type === 'info' && <ModalInfo modal={modal} close={close} />}
          {modal?.type === 'compare' && <ProductCompareModal data={modal.product} close={close} />}
        </ModalContent>
      </Modal>
    </ModalsContext.Provider>
  );
};
