import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useLastSeenStore } from './lastSeen.store';
import { LastSeenProduct } from './lastSeen.types';

const MAX_ITEMS = 5;
const MAX_AGE = 1000 * 60 * 60 * 24 * 7;

const filterProduct = (product: LastSeenProduct) => {
  const now = +new Date();
  return now - product._addedAt < MAX_AGE;
};

export const useLastSeen = () => {
  const [mounted, setMounted] = useState(false);
  const { products, setProducts } = useLastSeenStore(
    useShallow((state) => ({ products: state.products, setProducts: state.setProducts })),
  );

  const filteredProducts = products.filter(filterProduct);
  const productsCount = filteredProducts.length;

  const addProduct = (product: Omit<LastSeenProduct, '_addedAt'>) => {
    const newProduct = {
      ...product,
      _addedAt: +new Date(),
    };
    const newProducts = [newProduct, ...filteredProducts.filter((p) => p.sku !== product.sku)].slice(0, MAX_ITEMS);
    setProducts(newProducts);
  };

  useEffect(() => setMounted(true), [products]);

  return {
    products: filteredProducts,
    productsCount,
    addProduct,
    shouldDisplay: mounted && productsCount > 0,
  };
};
