import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
} from '@/constants/breakpoints.constants';

import { useMediaQuery } from './useMediaQueries';

interface SizeMap<T> {
  mobile: T;
  tablet?: T;
  laptop?: T;
  desktop?: T;
}

export const useBySize = <T>(data: SizeMap<T>) => {
  const { mobile, tablet, laptop, desktop } = data;
  const isMobile = useMediaQuery(BREAKPOINT_MOBILE);
  const isTablet = useMediaQuery(BREAKPOINT_TABLET);
  const isLaptop = useMediaQuery(BREAKPOINT_LAPTOP);
  const isDesktop = useMediaQuery(BREAKPOINT_DESKTOP);

  if (isDesktop && desktop !== undefined) {
    return desktop;
  }
  if (isLaptop && laptop !== undefined) {
    return laptop;
  }
  if (isTablet && tablet !== undefined) {
    return tablet;
  }
  if (isMobile && mobile !== undefined) {
    return mobile;
  }
  return undefined;
};
