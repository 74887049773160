import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { setCheckoutToken } from '@/logic/checkout/checkoutToken';
import { QueryKeys } from '@/network/query.types';

import { request } from '../Api';
import { CheckoutLine, SimpleCheckout } from '../GeneratedApi';

import { getCheckoutToken } from './../../logic/checkout/checkoutToken';

// Checkout
const fetchCheckout = async (token: string | null) => {
  if (!token) {
    return { lines: [] as CheckoutLine[], lines_count: 0, total_gross_amount: '0' };
  }

  try {
    const response = await request.checkoutRetrieve(token);
    return response;
  } catch (err) {
    const isAxiosError = axios.isAxiosError(err);
    if (isAxiosError) {
      const responseStatus = err.response?.status;
      if (responseStatus === 404 || responseStatus == 400) {
        setCheckoutToken(null);
      }
    }
    throw err;
  }
};

export const getCheckoutQueryOptions = (token: string | null) => ({
  queryKey: [QueryKeys.checkout],
  queryFn: () => fetchCheckout(token),
});

export function useGetCheckoutQuery() {
  const token = getCheckoutToken();
  return useQuery({
    ...getCheckoutQueryOptions(token),
    refetchOnWindowFocus: true,
    staleTime: 3000,
  });
}

// Checkout Simple

const fetchCheckoutSimple = async () => {
  const token = getCheckoutToken();
  if (!token) {
    return { lines_count: 0, total_gross_amount: '0' } satisfies Omit<SimpleCheckout, 'token'>;
  }

  try {
    const response = await request.checkoutSimpleRetrieve(token);
    return response;
  } catch (err) {
    const isAxiosError = axios.isAxiosError(err);
    if (isAxiosError) {
      const responseStatus = err.response?.status;
      if (responseStatus === 404 || responseStatus == 400) {
        setCheckoutToken(null);
      }
    }
    throw err;
  }
};

export function useGetCheckoutSimpleQuery() {
  return useQuery({
    queryKey: [QueryKeys.checkoutSimple],
    queryFn: () => fetchCheckoutSimple(),
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
}

// Checkout payment methods

export function useGetCheckoutPaymentMethodsQuery() {
  return useQuery({
    queryKey: [QueryKeys.checkoutPaymentMethods],
    queryFn: request.checkoutPaymentsMethodsList,
    staleTime: Infinity,
  });
}
