import React from 'react';

import { ProductLink } from '@/components/ProductLink';
import { ProductRemoveIcon } from '@/components/ProductsListItem/components/ProductRemoveIcon';
import { TyreImage } from '@/components/TyreImage';
import { Typography } from '@/components/_atoms/Typography';
import { ProductSeasonIcon } from '@/components/_molecules/ProductSeasonIcon';
import { CompareTyreProduct } from '@/logic/compareTyres';
import { getProductShortName } from '@/logic/product/products.utils';

interface Props extends CompareTyreProduct {
  onRemove?: (sku: string) => void;
}

export const CompareTyresItem = (props: Props) => {
  const { sku, tread, image, slug, isMain, producer, attributes, productionYear, season, onRemove } = props;

  return (
    <div className="group flex bg-white py-2 transition-colors hover:bg-grey-200">
      <div className="mr-1 flex shrink-0">
        <ProductLink slug={slug} sku={sku} isMain={isMain} className="inline-flex">
          <TyreImage
            unoptimized={image?.is_label}
            src={image?.url}
            alt={`${producer} ${tread}`}
            style={{
              width: 48,
              height: 48,
            }}
          />
        </ProductLink>
      </div>
      <div className="mr-1 mt-[7px]">
        <ProductSeasonIcon season={season} size={16} />
      </div>
      <div className="mr-4 flex flex-1 flex-col self-center">
        <ProductLink slug={slug} sku={sku} isMain={isMain}>
          <Typography
            as="span"
            className="text-text-grey-dark group-hover:text-text-orange-dark"
            variant="body_s"
            id="compareTyres.itemName"
            values={{
              producer,
              tread,
            }}
          />
        </ProductLink>
        <Typography as="p" variant="body_xs" className="mt-1">
          {getProductShortName({
            ...attributes,
            productionYear,
          })}
        </Typography>
      </div>
      {onRemove && (
        <div className="self-center opacity-0 transition-opacity group-hover:opacity-100">
          <ProductRemoveIcon onRemove={() => onRemove(sku)} />
        </div>
      )}
    </div>
  );
};
