import { AccountOrdersQuery } from 'pages/api/account/orders/[orderId]';
import { CustomIronSession } from 'server/auth/session.types';

import { AuthApiInstance } from '../Api';
import {
  ChangePassword,
  CreateOrder,
  CreateOrderResponse,
  PaginatedUserOrderListList,
  User,
  UserEdit,
  UserOrderDetail,
} from '../GeneratedApi';

// Use only on client site due to request to custom api routes
// https://nextjs.org/docs/pages/building-your-application/data-fetching/get-server-side-props#getserversideprops-or-api-routes

export interface SignInBody {
  email: string;
  password: string;
}

const getUser = () => AuthApiInstance.get<User>('/account/user').then((data) => data.data);
const updateUser = (data: UserEdit) => AuthApiInstance.put<void>('/account/user', data).then((data) => data.data);
const deleteUser = () => AuthApiInstance.delete<void>('/account/user').then((data) => data.data);
const changePassword = (data: ChangePassword) =>
  AuthApiInstance.post<void>('/account/change-password', data).then((data) => data.data);

const signIn = (data: SignInBody) => AuthApiInstance.post('/auth/signin', data).then((data) => data.data);
const signOut = () => AuthApiInstance.post('/auth/signout').then((data) => data.data);
const getSession = () =>
  AuthApiInstance.get<CustomIronSession['user'] | null>('/auth/session').then((data) => data.data);

const getAccountOrders = (params: AccountOrdersQuery) =>
  AuthApiInstance.get<PaginatedUserOrderListList>('/account/orders', {
    params,
  }).then((data) => data.data);
const getAccountOrderDetails = (orderId: string) =>
  AuthApiInstance.get<UserOrderDetail>(`/account/orders/${orderId}`).then((data) => data.data);

const createOrder = (data: CreateOrder) =>
  AuthApiInstance.post<CreateOrderResponse>('/order', data).then((data) => data.data);

export const AuthApi = {
  // user
  getUser,
  updateUser,
  deleteUser,
  changePassword,
  // auth
  signIn,
  signOut,
  getSession,
  // account orders
  getAccountOrders,
  getAccountOrderDetails,
  accountOrderInvoiceUrl: (orderId: string) => `/api/account/orders/${orderId}/invoice`,
  // orders
  createOrder,
};
