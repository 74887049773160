import { NonFalsy } from '@/types/common.types';

export const chunkArray = <T>(arr: T[], chunkSize: number) => {
  const tempArr = [...arr];
  const res = [];
  while (tempArr.length > 0) {
    const chunk = tempArr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
};

export const filterExisted = (arr: (string | undefined | null)[]) =>
  arr.filter((item): item is NonFalsy<string> => Boolean(item));

export const joinExisted = (arr: (string | null | undefined)[], separator = '') => filterExisted(arr).join(separator);

export function groupBy<T, K extends keyof any>(array: T[], keyFn: (item: T) => K): Record<K, T[]> {
  return array.reduce(
    (result, item) => {
      const key = keyFn(item);

      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(item);

      return result;
    },
    {} as Record<K, T[]>,
  );
}

export function getUniqueValues<T, U>(array: T[], keyFn: (item: T) => U): U[] {
  const uniqueValuesObj: { [key: string]: boolean } = {};
  const uniqueValues: U[] = [];

  array.forEach((item) => {
    const value = keyFn(item);
    const key = String(value);

    if (!uniqueValuesObj[key]) {
      uniqueValuesObj[key] = true;
      uniqueValues.push(value);
    }
  });

  return uniqueValues;
}
