import { startTransition, useCallback, useState } from 'react';

export const useModal = () => {
  const [isOpen, _setIsOpen] = useState(false);
  const open = useCallback(() => {
    startTransition(() => {
      _setIsOpen(true);
    });
  }, []);

  const close = useCallback(() => {
    startTransition(() => {
      _setIsOpen(false);
    });
  }, []);

  const setIsOpen = useCallback((newIsOpen: boolean) => {
    startTransition(() => {
      _setIsOpen(newIsOpen);
    });
  }, []);
  return {
    isOpen,
    setIsOpen,
    close,
    open,
  };
};
