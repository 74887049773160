import { FormattedMessage } from 'react-intl';

import settings from '@/preval/settings.preval';
import { cn } from '@/utils/styles.utils';

interface Props {
  light?: boolean;
}

export const OpeningHours = (props: Props) => {
  const { light } = props;
  const isHotlineAvailableOnSaturday = !!settings.saturday_start && !!settings.saturday_end;
  return (
    <span
      className={cn('text-body-m text-text-grey-dark', {
        'text-text-grey-super-light': !!light,
      })}
    >
      <FormattedMessage
        id="contact.openHours.mondayToFriday"
        values={{
          start: settings.monday_friday_start,
          end: settings.monday_friday_end,
        }}
      />
      {isHotlineAvailableOnSaturday ? (
        <span className="ml-2">
          <FormattedMessage
            id="contact.openHours.saturday"
            values={{
              start: settings.saturday_start,
              end: settings.saturday_end,
            }}
          />
        </span>
      ) : null}
    </span>
  );
};
