import { ParsedUrlQuery } from 'querystring';
import { decodeQueryParams, NumberParam, withDefault, StringParam } from 'serialize-query-params';

import { assertNever } from '@/types/common.types';
import { toArray } from '@/utils/functions.utils';

import { getCategoryPagePath, getTyreListingPageType } from '../searchParams/searchParams.utils';

import { DEFAULT_PRODUCT_LIMIT } from './tyresListing.constants';

export const getTyresListingPayload = (urlQuery: ParsedUrlQuery) => {
  const { tyrePageSlug, page, ordering, ...filters } = urlQuery;
  const decodedParams = decodeQueryParams(
    { page: withDefault(NumberParam, undefined), ordering: withDefault(StringParam, null) },
    { page, ordering },
  );
  const slugAsArray = toArray(tyrePageSlug);
  const pageType = getTyreListingPageType(slugAsArray);

  if (pageType === 'search') {
    return {
      page: decodedParams.page,
      type: pageType,
      searchParams: {
        ...filters,
        ...(decodedParams.ordering ? { ordering: decodedParams.ordering } : {}),
        ...getPagination(decodedParams.page),
      },
    } as const;
  }

  if (pageType === 'category') {
    return {
      page: decodedParams.page,
      type: pageType,
      searchParams: {
        path: getCategoryPagePath(slugAsArray, false),
        ...getPagination(decodedParams.page),
      },
    } as const;
  }

  return assertNever(pageType);
};

export const getTyresListingFiltersPayload = (urlQuery: ParsedUrlQuery) => {
  const { tyrePageSlug, page, ordering, ...filters } = urlQuery;

  const slugAsArray = toArray(tyrePageSlug);
  const pageType = getTyreListingPageType(slugAsArray);

  if (pageType === 'search') {
    return {
      pageType,
      filters,
    } as const;
  }

  if (pageType === 'category') {
    return {
      pageType,
      path: getCategoryPagePath(slugAsArray, false),
    } as const;
  }

  return assertNever(pageType);
};

const getPagination = (page = 1) => ({
  offset: (page - 1) * DEFAULT_PRODUCT_LIMIT,
  limit: DEFAULT_PRODUCT_LIMIT,
});

export const getOrderingObject = (ordering?: string) => (ordering ? { ordering } : {});
