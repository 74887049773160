import React, { useCallback, useState } from 'react';

import { DropdownWrapper } from '@/components/_molecules/DropdownWrapper/DropdownWrapper';
import { useLastSeen } from '@/logic/lastSeen';
import { cn } from '@/utils/styles.utils';

import { HeaderAction } from '../HeaderAction/HeaderAction';

import { HeaderLastSeenList } from './HeaderLastSeenList/HeaderLastSeenList';

export const HeaderLastSeen = () => {
  const [isHover, setIsHover] = useState(false);

  const { products, shouldDisplay, productsCount } = useLastSeen();
  const close = useCallback(() => setIsHover(false), []);
  const open = () => {
    setIsHover(true);
  };

  return (
    <div className="relative h-full" onMouseEnter={open} onMouseLeave={close}>
      <HeaderAction
        icon="eye"
        badge={shouldDisplay ? productsCount : undefined}
        textId="header.actions.lastSeen"
        isHover={isHover}
      />
      {shouldDisplay && (
        <DropdownWrapper
          isOpen={isHover}
          className={cn(
            '[--width:360px] laptop:[--width:440px]',
            'absolute left-[calc(-1*var(--width)/2+50%)] top-[calc(100%-4px)] w-[--width]',
          )}
        >
          <HeaderLastSeenList close={close} products={products} />
        </DropdownWrapper>
      )}
    </div>
  );
};
