import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';

import { Icon, Icons } from '@/components/_atoms/Icon';
import { Spinner } from '@/components/_atoms/Spinner';
import { textSizeMap } from '@/components/_atoms/Typography/Typography';
import { Color } from '@/types/colors.types';
import { cn } from '@/utils/styles.utils';

import { ButtonSize, ButtonVariants } from './Button';

const buttonContentVariants = cva('flex items-center justify-center', {
  variants: {
    size: {
      xs: 'gap-1',
      s: 'gap-1',
      m: 'gap-2',
      l: 'gap-2',
    },
  },
  defaultVariants: {
    size: 'm',
  },
});

export interface ButtonContentProps extends VariantProps<ButtonVariants> {
  text?: string;
  iconName?: Icons;
  iconColor?: Color;
  color?: 'standard' | 'destructive';
  iconBadge?: number;
  loading?: boolean;
}

const buttonSizeToParams: Record<
  ButtonSize,
  {
    textClassName: string;
    iconSize: number;
  }
> = {
  xs: {
    textClassName: textSizeMap.body_xs,
    iconSize: 16,
  },
  s: {
    textClassName: textSizeMap.body_s,
    iconSize: 16,
  },
  m: {
    textClassName: textSizeMap.body_m,
    iconSize: 24,
  },
  l: {
    textClassName: textSizeMap.body_l,
    iconSize: 24,
  },
};

export const ButtonContent = (props: ButtonContentProps) => {
  const { variant = 'primary', size, iconName, iconColor = 'grey-100', text, iconBadge, loading = false } = props;
  const { textClassName, iconSize } = buttonSizeToParams[size || 'm'];

  return (
    <>
      {loading && (
        <div className={'absolute inset-0 flex items-center justify-center'}>
          <Spinner visible color={variant === 'subtle' ? 'orange' : 'white'} size="s" />
        </div>
      )}
      <div
        className={cn(buttonContentVariants({ size }), {
          'opacity-0': loading,
        })}
      >
        {iconName ? <Icon name={iconName} size={iconSize} color={iconColor} badge={iconBadge} /> : null}
        {text ? <span className={cn(textClassName, 'font-bold')}>{text}</span> : null}
      </div>
    </>
  );
};
