import {
  DataLayerAddToCartData,
  DataLayerBeginCheckoutData,
  DataLayerEvent,
  DataLayerPromotionData,
  DataLayerPurchaseData,
  DataLayerRemoveFromCartData,
  DataLayerSelectItemData,
  DataLayerViewCartData,
  DataLayerViewItemData,
  DataLayerViewItemListData,
} from './DataLayer.types';

type WindowWithDataLayer = Window & {
  dataLayer?: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export class DataLayerService {
  private static push(event: DataLayerEvent) {
    if (!window) {
      console.error('DataLayerService - window not available');
      return;
    }
    window.dataLayer?.push({ ecommerce: null }); // clear ecommerce data
    window.dataLayer?.push(event);
  }

  static purchase(data: DataLayerPurchaseData) {
    DataLayerService.push({
      event: 'purchase',
      ecommerce: data,
    });
  }

  static addToCart(data: DataLayerAddToCartData) {
    DataLayerService.push({
      event: 'add_to_cart',
      ecommerce: data,
    });
  }

  static removeFromCard(data: DataLayerRemoveFromCartData) {
    DataLayerService.push({
      event: 'remove_from_cart',
      ecommerce: data,
    });
  }

  static viewCart(data: DataLayerViewCartData) {
    DataLayerService.push({
      event: 'view_cart',
      ecommerce: data,
    });
  }

  static beginCheckout(data: DataLayerBeginCheckoutData) {
    DataLayerService.push({
      event: 'begin_checkout',
      ecommerce: data,
    });
  }

  static viewList(data: DataLayerViewItemListData) {
    DataLayerService.push({
      event: 'view_item_list',
      ecommerce: data,
    });
  }

  static selectItem(data: DataLayerSelectItemData) {
    DataLayerService.push({
      event: 'select_item',
      ecommerce: data,
    });
  }

  static viewItem(data: DataLayerViewItemData) {
    DataLayerService.push({
      event: 'view_item',
      ecommerce: data,
    });
  }

  static viewPromotion(data: DataLayerPromotionData) {
    DataLayerService.push({
      event: 'view_promotion',
      ecommerce: data,
    });
  }

  static selectPromotion(data: DataLayerPromotionData) {
    DataLayerService.push({
      event: 'select_promotion',
      ecommerce: data,
    });
  }
}
