import axios, { AxiosError } from 'axios';
import QS from 'qs';

import { BASE_URL } from '@/constants/config.constants';

import { AxiosApi } from './GeneratedApi';

export const ApiInstance = new AxiosApi({
  baseURL: BASE_URL,
  paramsSerializer: (params) => QS.stringify(params, { arrayFormat: 'comma' }),
});

export const ServerApiInstance = new AxiosApi({
  baseURL: BASE_URL,
  paramsSerializer: (params) => QS.stringify(params, { arrayFormat: 'comma' }),
});

export const AuthApiInstance = axios.create({
  baseURL: '/api/',
  paramsSerializer: (params) => QS.stringify(params, { arrayFormat: 'comma' }),
});

export const request = ApiInstance.api;

type BackendError = {
  error_code: string;
};

export const isBackendError = (error: any): error is BackendError =>
  error && typeof error === 'object' && error !== null && 'error_code' in error && typeof error.error_code === 'string';

export const isApiError = (error: any): error is AxiosError<BackendError> => {
  return axios.isAxiosError(error) && isBackendError(error.response?.data);
};

export const getApiErrorCode = (error: AxiosError<BackendError>) => error.response?.data.error_code;

export const ApiErrorCodes = {
  register: {
    EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  },
  forgotPassword: {
    USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
  },
  changePassword: {
    INVALID_OLD_PASSWORD: 'INVALID_OLD_PASSWORD',
  },
  createOrder: {
    VARIANT_SOLD_OUT: 'VARIANT_SOLD_OUT',
  },
  createUpdateCheckout: {
    VARIANT_SOLD_OUT: 'VARIANT_SOLD_OUT',
  },
  getProductSales: {
    VARIANT_SOLD_OUT: 'VARIANT_SOLD_OUT',
  },
};
