import * as Portal from '@radix-ui/react-portal';
import { m } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { useBySize } from '@/hooks/useBySize';

import { Spinner } from '../_atoms/Spinner';
import { ClientMounted } from '../_molecules/ClientMounted';
import { useModal } from '../_molecules/Modal';

export const RouterSpinner = () => {
  const { close, isOpen, open } = useModal();
  const router = useRouter();

  const enableSpinner = useBySize({
    mobile: true,
    laptop: false,
  });

  const handleRouteChangeStart = (url: any, options: any) => {
    if (!options.shallow) {
      open();
    }
  };

  const routeChangeComplete = () => close();
  useEffect(() => {
    if (enableSpinner) {
      router.events.on('routeChangeStart', handleRouteChangeStart);
      router.events.on('routeChangeComplete', routeChangeComplete);
    }

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [enableSpinner, router.events]);
  return (
    <>
      <ClientMounted>
        <Portal.Root>
          {/* Not remove scroll bar increase INP for mobile */}
          <RemoveScroll enabled={isOpen} removeScrollBar={false}>
            <m.div
              className="fixed inset-0 z-[400] flex items-center justify-center"
              key="modal"
              initial="collapsed"
              animate={isOpen ? 'open' : 'collapsed'}
              variants={{
                open: { opacity: 1, display: 'flex' },
                collapsed: {
                  opacity: 0,
                  transitionEnd: {
                    display: 'none',
                  },
                },
              }}
              transition={{ duration: 0.2 }}
            >
              <div className="absolute inset-0 bg-white opacity-30" />
              {isOpen ? <Spinner visible /> : null}
            </m.div>
          </RemoveScroll>
        </Portal.Root>
      </ClientMounted>
    </>
  );
};
