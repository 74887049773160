import React from 'react';

import { Typography } from '@/components/_atoms/Typography';

export const CompareTyresItemEmpty = () => {
  return (
    <div className="flex h-full items-center rounded-lg pl-4">
      <Typography as="span" color="grey-semi" variant="body_s" id="compareTyres.itemEmpty" />
    </div>
  );
};
