import React from 'react';

import { useTranslations } from '@/hooks/useTranslations';
import { MessagesKey } from '@/types/translations.types';
import { cn } from '@/utils/styles.utils';

const variantsMapping = {
  header_xxxl: 'h1',
  header_xxl: 'h2',
  header_xl: 'h3',
  header_price_xl: 'h3',
  header_l: 'h4',
  body_xxl: 'p',
  body_xl: 'p',
  body_l: 'p',
  body_m: 'p',
  body_s: 'p',
  body_xs: 'p',
  body_xxs: 'p',
} as const;

export type TypographyVariant = keyof typeof variantsMapping;

type TypographyTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

export const textSizeMap: Record<TypographyVariant, string> = {
  header_xxxl: 'text-header-xxxl',
  header_xxl: 'text-header-xxl',
  header_xl: 'text-header-xl',
  header_price_xl: 'text-header-price-xl',
  header_l: 'text-header-l',
  body_xxl: 'text-body-xxl',
  body_xl: 'text-body-xl',
  body_l: 'text-body-l',
  body_m: 'text-body-m',
  body_s: 'text-body-s',
  body_xs: 'text-body-xs',
  body_xxs: 'text-body-xxs',
};

export type TextColor =
  | 'white'
  | 'blue'
  | 'orange'
  | 'orange-dark'
  | 'grey-dark'
  | 'grey-standard'
  | 'grey-semi'
  | 'grey-light'
  | 'grey-super-light'
  | 'red'
  | 'green';

const colorMap: Record<TextColor, string> = {
  white: 'text-text-white',
  blue: 'text-text-blue',
  orange: 'text-text-orange',
  'orange-dark': 'text-text-orange-dark',
  'grey-dark': 'text-text-grey-dark',
  'grey-standard': 'text-text-grey-standard',
  'grey-semi': 'text-text-grey-semi',
  'grey-light': 'text-text-grey-light',
  'grey-super-light': 'text-text-grey-super-light',
  red: 'text-text-red',
  green: 'text-text-green',
};

export type TypographyProps = {
  variant?: TypographyVariant;
  as?: TypographyTag;
  bold?: boolean;
  underline?: boolean;
  color?: TextColor;
  className?: string;
  id?: MessagesKey;
  children?: React.ReactNode;
  // TODO
  values?: any;
  style?: React.CSSProperties;
};

export const Typography: React.FC<TypographyProps> = (props) => {
  const { t } = useTranslations();
  const {
    values = {},
    variant = 'body_m',
    children,
    id,
    as,
    underline,
    color = 'grey-dark',
    bold = false,
    className,
    ...restProps
  } = props;

  const Component = as || variantsMapping[variant] || 'p';
  return (
    <Component
      className={cn(
        textSizeMap[variant],
        colorMap[color],
        {
          'font-bold': bold,
          underline: underline,
        },
        className,
      )}
      {...restProps}
    >
      {id
        ? t(id, {
            br: <br />,
            bold: (msg: Array<string>) => <span className="font-bold">{msg}</span>,
            ...values,
          })
        : children}
    </Component>
  );
};
