import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { mapVariant } from '@/logic/listingProductItem/listingProductItem.mappers';

import { request } from '../Api';
import { QueryKeys } from '../query.types';

export function fetchCompareTyres(sku: string[]) {
  return request.tyresCompareList({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sku,
  });
}

export function useCompareTyresQuery(sku: string[], enabled: boolean) {
  return useQuery({
    queryKey: [QueryKeys.compare, sku],
    queryFn: () => fetchCompareTyres(sku),
    enabled,
    select: (data) => data.map((item) => mapVariant({ item, type: 'listing' })),
    placeholderData: keepPreviousData,
  });
}
