import { useRouter } from 'next/router';
import React from 'react';

import { Button } from '@/components/_molecules/Button';
import { useLogout } from '@/logic/auth/useLogout';
import { Routes } from '@/routes/routes';

interface Props {
  isAuth: boolean;
  close: () => void;
}

export const HeaderMobileMenuButtons = (props: Props) => {
  const { isAuth, close } = props;
  const router = useRouter();
  const logout = useLogout();
  const onSignIn = () => {
    close();
    router.push(Routes.login);
  };
  const onRegister = () => {
    close();
    router.push(Routes.register);
  };
  const onAccount = () => {
    close();
    router.push(Routes.account);
  };
  const onLogout = () => {
    close();
    logout();
  };

  return (
    <div className={'grid grid-cols-2 gap-4 p-4'}>
      {isAuth ? (
        <>
          <Button variant="primary" textId="mobileMenu.account" className="w-full" onClick={onAccount} />
          <Button variant="secondary" textId="mobileMenu.logout" className="w-full" onClick={onLogout} />
        </>
      ) : (
        <>
          <Button variant="primary" textId="mobileMenu.signIn" className="w-full" onClick={onSignIn} />
          <Button variant="secondary" textId="mobileMenu.register" className="w-full" onClick={onRegister} />
        </>
      )}
    </div>
  );
};
