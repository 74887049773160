import { useRouter } from 'next/router';
import React, { Fragment, useDeferredValue, useEffect } from 'react';

import { COMPARE_TYRES_MAX_ITEMS, COMPARE_TYRES_MIN_ITEMS } from '@/logic/compareTyres/compareTyres.constatns';
import { useCompareTyresStore } from '@/logic/compareTyres/useCompareTyresStore';
import { Routes } from '@/routes/routes';
import { cn } from '@/utils/styles.utils';

import { Container } from '../_atoms/Container';
import { Button } from '../_molecules/Button';
import { ButtonProps } from '../_molecules/Button/Button';

import { CompareTyresItem } from './CompareTyresItem/CompareTyresItem';
import { CompareTyresItemEmpty } from './CompareTyresItemEmpty';

const DISPLAY_ON_ROUTES = ['/opony/[...tyrePageSlug]', '/dane-opony/[tyreSlug]'];

const DATA_ATTRIBUTE = 'data-compare-tyres';

export const CompareTyres = React.memo(() => {
  const { initializeTyreToCompare, compareTyres, removeTyreToCompare } = useCompareTyresStore();
  const router = useRouter();

  const shouldDisplayOnRoute = DISPLAY_ON_ROUTES.includes(router.route);
  const _isVisible = compareTyres.length !== 0 && shouldDisplayOnRoute;
  const isVisible = useDeferredValue(_isVisible);

  const active = compareTyres.length > COMPARE_TYRES_MIN_ITEMS;
  const buttonProps = {
    disabled: !active,
    variant: 'secondary',
    textId: 'compareTyres.bar.compare',
    values: {
      count: compareTyres.length,
      max: COMPARE_TYRES_MAX_ITEMS,
    },
    onClick: () => router.push(Routes.compare),
  } satisfies ButtonProps;

  useEffect(() => {
    initializeTyreToCompare();
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.body.setAttribute(DATA_ATTRIBUTE, 'true');
    } else {
      document.body.removeAttribute(DATA_ATTRIBUTE);
    }
  }, [isVisible]);

  const _compareTyres = [...compareTyres, ...Array<null>(COMPARE_TYRES_MAX_ITEMS - compareTyres.length).fill(null)];

  if (!shouldDisplayOnRoute) {
    return null;
  }

  return (
    <div
      className={cn(
        'fixed bottom-0 left-0 right-0 z-[var(--z-index-bottom-sub-bar)] translate-y-[100%] bg-white shadow-top transition duration-200',
        {
          'translate-y-[calc(-1*var(--tyre-details-bottom-bar-offset,0))]': isVisible,
        },
      )}
    >
      <Container>
        <div className={cn('hidden h-[var(--compare-tyres-bottom-bar)] laptop:flex laptop:items-center')}>
          <div className={'grid h-full flex-1 grid-cols-3 items-center'}>
            {_compareTyres.map((item, index) => (
              <Fragment key={item?.sku || index}>
                {item ? <CompareTyresItem {...item} onRemove={removeTyreToCompare} /> : <CompareTyresItemEmpty />}
              </Fragment>
            ))}
          </div>
          <div className="ml-6">
            <Button {...buttonProps} size="s" />
          </div>
        </div>
        <div className={'flex h-[var(--compare-tyres-bottom-bar)] items-center laptop:hidden'}>
          <Button {...buttonProps} className="w-full" />
        </div>
      </Container>
    </div>
  );
});

CompareTyres.displayName = 'CompareTyres';
