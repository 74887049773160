import Link from 'next/link';
import React from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { LinkButton } from '@/components/_molecules/Button/LinkButton';
import { useGetCheckoutSimple } from '@/logic/checkout/useGetCheckoutSimple';

interface Props {
  device: 'mobile' | 'desktop';
}

export const HeaderBasketElement = (props: Props) => {
  const { device } = props;
  const { linesCount } = useGetCheckoutSimple();
  if (device === 'desktop') {
    return (
      <LinkButton
        href="/koszyk"
        size="l"
        variant="transparent"
        iconName="bag"
        iconColor="grey-1000"
        textId={'header.actions.basket'}
        iconBadge={linesCount}
        className="px-3"
      />
    );
  }
  return (
    <Link href="/koszyk" aria-label="Przejdź do koszyka">
      <Icon name="bag" size={32} badge={linesCount} />
    </Link>
  );
};
