import {
  ABOUT_US_STATIC_PAGE_PATH,
  COMPLAINT_STATIC_PAGE_PATH,
  COOKIES_STATIC_PAGE_PATH,
  DELIVERY_STATIC_PAGE_PATH,
  INFO_FOR_CLIENTS_STATIC_PAGE_PATH,
  PRIVACY_POLICY_STATIC_PAGE_PATH,
  RETURNS_STATIC_PAGE_PATH,
  RULES_STATIC_PAGE_PATH,
  SITE_MAP_CARS_STATIC_PAGE_PATH,
  SITE_MAP_PRODUCERS_STATIC_PAGE_PATH,
  SITE_MAP_STATIC_PAGE_PATH,
  WHY_US_STATIC_PAGE_PATH,
} from '@/logic/staticPage';
import footerData from '@/preval/footer.preval';

import { FooterListData } from './footer.types';

export const CATEGORIES_LIST: FooterListData = {
  text: footerData.categories.group_name,
  items: footerData.categories.values.map((item) => ({
    text: item.name,
    path: item.path,
  })),
};

export const PRODUCERS_LIST: FooterListData = {
  text: footerData.producers.group_name,
  items: footerData.producers.values.map((item) => ({
    text: item.name,
    path: item.path,
  })),
};

export const FOR_CLIENTS_LIST: FooterListData = {
  textId: 'footer.forClientsList.header',
  items: [
    {
      textId: 'footer.forClientsList.delivery',
      path: DELIVERY_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.forClientsList.returns',
      path: RETURNS_STATIC_PAGE_PATH,
    },

    {
      textId: 'footer.forClientsList.complaints',
      path: COMPLAINT_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.forClientsList.help',
      path: INFO_FOR_CLIENTS_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.forClientsList.rules',
      path: RULES_STATIC_PAGE_PATH,
    },
  ],
};

export const ABOUT_LIST: FooterListData = {
  textId: 'footer.aboutList.header',
  items: [
    {
      textId: 'footer.aboutList.aboutUs',
      path: ABOUT_US_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.aboutList.why',
      path: WHY_US_STATIC_PAGE_PATH,
    },
  ],
};

export const LEGAL_LIST: FooterListData = {
  textId: 'footer.legalList.header',
  items: [
    {
      textId: 'footer.legalList.privacyPolicy',
      path: PRIVACY_POLICY_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.legalList.cookies',
      path: COOKIES_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.legalList.siteMap',
      path: SITE_MAP_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.legalList.siteMapProducers',
      path: SITE_MAP_PRODUCERS_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.legalList.siteMapCars',
      path: SITE_MAP_CARS_STATIC_PAGE_PATH,
    },
  ],
};

export const LEGAL_LIST_SHORT: FooterListData = {
  textId: 'footer.legalList.header',
  items: [
    {
      textId: 'footer.legalList.rules',
      path: RULES_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.legalList.privacyPolicy',
      path: PRIVACY_POLICY_STATIC_PAGE_PATH,
    },
    {
      textId: 'footer.legalList.cookies',
      path: COOKIES_STATIC_PAGE_PATH,
    },
  ],
};
