import { createContext, useContext } from 'react';
import { createStore, useStore as useZustandStore } from 'zustand';

import { CompareTyreProduct } from '@/logic/compareTyres/compareTyres.types';
import { SelectCarValueWithSize } from '@/logic/selectCar';
import { SelectedTyreValue } from '@/logic/selectedTyreConfig';

interface StoreInterface {
  selectedCar: SelectCarValueWithSize | null;
  setSelectedCar: (data: SelectCarValueWithSize | null) => void;
  selectedTyre: SelectedTyreValue | null;
  setSelectedTyre: (data: SelectedTyreValue) => void;
  compareTyres: CompareTyreProduct[] | null;
  setCompareTyres: (data: CompareTyreProduct[] | null) => void;
}

const getDefaultInitialState = () => ({
  selectedCar: null,
  compareTyres: null,
  selectedTyre: null,
});

const zustandContext = createContext<AppStore | null>(null);

export type AppStore = ReturnType<typeof initializeStore>;

export const Provider = zustandContext.Provider;

export const useAppStore = <T>(selector: (state: StoreInterface) => T) => {
  const store = useContext(zustandContext);

  if (!store) throw new Error('Store is missing the provider');

  return useZustandStore(store, selector);
};

export const initializeStore = (preloadedState: Partial<StoreInterface> = {}) => {
  return createStore<StoreInterface>((set) => ({
    ...getDefaultInitialState(),
    ...preloadedState,
    setSelectedCar: (selectedCar) => {
      set({
        selectedCar,
      });
    },
    setCompareTyres: (compareTyres) => {
      set({
        compareTyres,
      });
    },
    setSelectedTyre(selectedTyre) {
      set({
        selectedTyre,
      });
    },
  }));
};
