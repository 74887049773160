import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@/network/query.types';

import { request } from '../Api';

const REVIEWS_PAGE_SIZE = 5;

interface ReviewsParams {
  productSlug?: string;
  treadSlug?: string;
}

export const getNextPageParam = (lastPage: { count?: number; page: number }) => {
  const { count = 0, page } = lastPage;
  const nextPage = page + 1;

  if ((nextPage - 1) * REVIEWS_PAGE_SIZE < count) {
    return nextPage;
  }
  return undefined;
};

export function orderLineReviewFetch(orderLineId: string) {
  return request.tyresReviewsRetrieve(orderLineId);
}

export async function tyreReviewsFetch(params: ReviewsParams, page = 1) {
  const result = await request.tyresReviewsList({
    product_slug: params.productSlug,
    tread_slug: params.treadSlug,
    limit: REVIEWS_PAGE_SIZE,
    offset: (page - 1) * REVIEWS_PAGE_SIZE,
  });

  return {
    ...result,
    page,
  };
}

export const useGetOrderLineReviewQuery = (orderLineId: string) => {
  return useQuery({
    queryKey: [QueryKeys.orderLineReview, orderLineId],
    queryFn: () => orderLineReviewFetch(orderLineId),
  });
};

export const useGetTyreReviewsQuery = (params: ReviewsParams) => {
  return useInfiniteQuery({
    initialPageParam: 1,
    getNextPageParam,
    queryKey: [QueryKeys.tyreReviews, params],
    queryFn: ({ pageParam }) => tyreReviewsFetch(params, pageParam),
  });
};
