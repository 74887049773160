import Link from 'next/link';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { FACEBOOK_URL, INSTAGRAM_URL } from '@/constants/contact.constants';

import { LEGAL_LIST } from '../../footer.data';

const FooterBottomSocial = () => (
  <div className="flex gap-10">
    <a className="group" href={FACEBOOK_URL} aria-label="Facebook" target="_blank" rel="noreferrer">
      <Icon name="facebook" size={32} className="fill-white transition-colors group-hover:fill-orange-500" />
    </a>
    <a className="group" href={INSTAGRAM_URL} aria-label="Instagram" target="_blank" rel="noreferrer">
      <Icon name="instagram" size={32} className="fill-white transition-colors group-hover:fill-orange-500" />
    </a>
  </div>
);

const FooterBottomList = () => (
  <nav>
    <ul className="flex justify-between laptop:justify-start laptop:gap-4">
      {LEGAL_LIST.items.map((item) => (
        <li key={item.textId}>
          <Link href={item.path} className="group">
            <Typography
              variant="body_s"
              as="span"
              id={item.textId}
              className="inline-flex text-text-grey-super-light transition-all group-hover:translate-x-2 group-hover:text-text-white"
            >
              {item.text}
            </Typography>
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

const FooterBottomCopy = () => (
  <Typography
    variant="body_s"
    color="grey-super-light"
    as="span"
    id="footerInfo.copy"
    values={{
      year: new Date().getFullYear(),
    }}
  />
);

export const FooterBottom = () => {
  return (
    <div className="pb-8 pt-6 tablet:pt-8 laptop:pt-6">
      {/* mobile, tablet, laptop */}
      <div className="flex flex-col tablet:gap-10 laptop:gap-8 desktop:hidden">
        <div className="hidden tablet:max-laptop:block">
          <FooterBottomList />
        </div>
        <div className="hidden laptop:block">
          <FooterBottomSocial />
        </div>
        <div className="flex items-center justify-between">
          <div className="hidden tablet:max-laptop:block">
            <FooterBottomSocial />
          </div>
          <div className="hidden laptop:block">
            <FooterBottomList />
          </div>
          <FooterBottomCopy />
        </div>
        <div className="mt-6 block tablet:hidden">
          <FooterBottomSocial />
        </div>
      </div>
      {/* desktop */}
      <div className="hidden grid-cols-4 items-center gap-6 desktop:grid">
        <div className="col-span-1">
          <FooterBottomSocial />
        </div>
        <div className="col-span-2">
          <FooterBottomList />
        </div>
        <div className="col-span-1">
          <FooterBottomCopy />
        </div>
      </div>
    </div>
  );
};
