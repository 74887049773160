export const DELIVERY_STATIC_PAGE_SLUG = 'dostawa';
export const DELIVERY_STATIC_PAGE_PATH = `/${DELIVERY_STATIC_PAGE_SLUG}`;

export const INFO_FOR_CLIENTS_STATIC_PAGE_SLUG = 'informacje-dla-kupujacych';
export const INFO_FOR_CLIENTS_STATIC_PAGE_PATH = `/${INFO_FOR_CLIENTS_STATIC_PAGE_SLUG}`;

export const RETURNS_STATIC_PAGE_SLUG = 'zwrot-i-wymiana';
export const RETURNS_STATIC_PAGE_PATH = `/${RETURNS_STATIC_PAGE_SLUG}`;

export const RULES_STATIC_PAGE_SLUG = 'regulamin';
export const RULES_STATIC_PAGE_PATH = `/${RULES_STATIC_PAGE_SLUG}`;

export const COMPLAINT_STATIC_PAGE_SLUG = 'reklamacja';
export const COMPLAINT_STATIC_PAGE_PATH = `/${COMPLAINT_STATIC_PAGE_SLUG}`;

export const ABOUT_US_STATIC_PAGE_SLUG = 'o-nas';
export const ABOUT_US_STATIC_PAGE_PATH = `/${ABOUT_US_STATIC_PAGE_SLUG}`;

export const WHY_US_STATIC_PAGE_SLUG = 'dlaczego-warto';
export const WHY_US_STATIC_PAGE_PATH = `/${WHY_US_STATIC_PAGE_SLUG}`;

export const PRIVACY_POLICY_STATIC_PAGE_SLUG = 'polityka-prywatnosci';
export const PRIVACY_POLICY_STATIC_PAGE_PATH = `/${PRIVACY_POLICY_STATIC_PAGE_SLUG}`;

export const COOKIES_STATIC_PAGE_SLUG = 'polityka-cookies';
export const COOKIES_STATIC_PAGE_PATH = `/${COOKIES_STATIC_PAGE_SLUG}`;

export const SITE_MAP_STATIC_PAGE_PATH = '/mapa-strony';
export const SITE_MAP_PRODUCERS_STATIC_PAGE_PATH = '/mapa-strony-producenci';
export const SITE_MAP_CARS_STATIC_PAGE_PATH = '/mapa-strony-auta';
