import { ParsedUrlQuery } from 'querystring';
import { decodeQueryParams, StringParam, withDefault } from 'serialize-query-params';

import { SITE_URL } from '@/constants/config.constants';

export const getSlug = (key: string, query: ParsedUrlQuery) => {
  const { [key]: value, ...restQuery } = query;
  const { slug = '' } = decodeQueryParams(
    { slug: withDefault(StringParam, '') },
    {
      slug: value,
    },
  );

  return {
    slug,
    restQuery,
  };
};

export const getTyreDetailsUrl = (slug: string) => `/dane-opony/${slug}`;
export const getTyreModelUrl = (slug: string) => `/model-opony/${slug}`;
export const getTyreListingUrl = (slug: string[]) => `/opony/${slug.join('/')}`;
export const getBlogPostUrl = (categorySlug: string, postSlug: string) => `/blog/${categorySlug}/${postSlug}`;

export const getPathWithDomain = (path: string | undefined) => (path ? `${SITE_URL}${path}` : undefined);

export const getPageObject = (page?: number) => (page && page !== 1 ? { page } : {});
