import Link from 'next/link';
import React, { ComponentProps } from 'react';

import { useTranslations } from '@/hooks/useTranslations';
import { cn } from '@/utils/styles.utils';

import { BaseButtonProps, buttonVariants } from './Button';
import { ButtonContent } from './ButtonContent';

type LinkButtonProps = ComponentProps<typeof Link> & BaseButtonProps;

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => {
  const { t } = useTranslations();
  const {
    variant = 'primary',
    size,
    iconName,
    iconColor = 'grey-100',
    color = 'standard',
    text,
    textId,
    className,
    values,
    iconBadge,
    loading = false,
    changeColorOnActive = true,
    ...restProps
  } = props;

  const isOnlyIcon = iconName && !text && !textId;
  const contentText = textId ? t(textId, values) : text;
  return (
    <Link
      ref={ref}
      className={cn(buttonVariants({ variant, size }), className)}
      data-active-change-color={changeColorOnActive}
      data-destructive={color === 'destructive'}
      data-square={isOnlyIcon}
      {...restProps}
    >
      <ButtonContent
        iconColor={iconColor}
        color={color}
        iconBadge={iconBadge}
        iconName={iconName}
        loading={loading}
        size={size}
        variant={variant}
        text={contentText}
      />
    </Link>
  );
});

LinkButton.displayName = 'LinkButton';
