import React from 'react';

import { Container } from '@/components/_atoms/Container/Container';
import { cn } from '@/utils/styles.utils';

import { Footer } from '../Footer';
import { Header } from '../Header';

interface Props {
  children: React.ReactElement;
  background?: 'grey' | 'white';
}
export const Layout = (props: Props) => {
  const { children, background = 'white' } = props;

  return (
    <div>
      <Header />
      <main
        className={cn('bg-white', {
          ['bg-grey-300']: background === 'grey',
        })}
      >
        <Container>{children}</Container>
      </main>
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};
