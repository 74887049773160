import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { LastSeenProduct } from './lastSeen.types';

interface LastSeenStore {
  products: LastSeenProduct[];
  setProducts: (product: LastSeenProduct[]) => void;
}

export const useLastSeenStore = create(
  persist<LastSeenStore>(
    (set) => ({
      products: [],
      setProducts: (products: LastSeenProduct[]) => set({ products }),
    }),
    {
      name: 'last-seen-products-01',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
