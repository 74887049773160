import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const Container = (props: Props) => {
  const { children } = props;
  return <div className="mx-auto my-0 w-full max-w-screen-desktop px-4 py-0 laptop:px-8">{children}</div>;
};
