import Link from 'next/link';
import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { MenuCategory } from '@/logic/menuCategories';
import { GroupPath } from '@/network/GeneratedApi';
import menu from '@/preval/menu.preval';
import { cn } from '@/utils/styles.utils';

const HeaderMenuList = (props: GroupPath & { closeMenu: () => void; columns?: number }) => {
  const { group_name: name, values, columns, closeMenu } = props;
  return (
    <div>
      <Typography variant="body_l" bold className="mb-2">
        {name}
      </Typography>
      <ul
        className={cn({
          'columns-4': columns === 4,
          'columns-3': columns === 3,
          'columns-2': columns === 2,
        })}
      >
        {values.map((value) => (
          <li key={value.path} className="mb-2">
            <Link href={value.path} className="after:ml-1 after:content-['»'] hover:underline" onClick={closeMenu}>
              <Typography as="span" variant="body_m" color="grey-standard">
                {value.name}
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface Props {
  closeMenu: () => void;
  activeCategory: MenuCategory;
}

const contentLeftClass = cn('mr-16 flex-1 border-r border-grey-300');
const contentRightClass = cn('flex-[3] space-y-4');

export const HeaderMenu = (props: Props) => {
  const { closeMenu, activeCategory } = props;
  const { tyreMenu, winterTyreMenu, summerTyreMenu } = menu;

  if (activeCategory === 'WINTER_TYRES') {
    return (
      <div className="flex p-8">
        <div className={contentRightClass}>
          <HeaderMenuList closeMenu={closeMenu} {...winterTyreMenu.winter_producers} columns={4} />
          <HeaderMenuList closeMenu={closeMenu} {...winterTyreMenu.winter_sizes} columns={4} />
          <HeaderMenuList closeMenu={closeMenu} {...winterTyreMenu.winter_rims} columns={4} />
        </div>
      </div>
    );
  }

  if (activeCategory === 'SUMMER_TYRES') {
    return (
      <div className="flex p-8">
        <div className={contentRightClass}>
          <HeaderMenuList closeMenu={closeMenu} {...summerTyreMenu.summer_producers} columns={4} />
          <HeaderMenuList closeMenu={closeMenu} {...summerTyreMenu.summer_sizes} columns={4} />
          <HeaderMenuList closeMenu={closeMenu} {...summerTyreMenu.summer_rims} columns={4} />
        </div>
      </div>
    );
  }

  if (activeCategory === 'TYRES') {
    return (
      <div className="flex p-8">
        <div className={contentLeftClass}>
          <div className="mb-6">
            <HeaderMenuList closeMenu={closeMenu} {...tyreMenu.seasons} />
          </div>
          <HeaderMenuList closeMenu={closeMenu} {...tyreMenu.tyre_types} />
        </div>
        <div className={contentRightClass}>
          <HeaderMenuList closeMenu={closeMenu} {...tyreMenu.producers} columns={4} />
          <HeaderMenuList closeMenu={closeMenu} {...tyreMenu.popular_sizes} columns={4} />
          <HeaderMenuList closeMenu={closeMenu} {...tyreMenu.popular_models} columns={3} />
        </div>
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const leftover: never = activeCategory;
  return null;
};
