import React from 'react';

import { OpeningHours } from '@/components/OpeningHours';
import { Typography } from '@/components/_atoms/Typography';
import { EMAIL } from '@/constants/contact.constants';

interface Props {
  color?: 'grey-dark' | 'grey-super-light';
}

export const HeaderContact = (props: Props) => {
  const { color = 'grey-super-light' } = props;
  return (
    <div className="inline-flex items-center">
      <Typography variant="body_m" as="span" color={color}>
        <a href={`mailto:${EMAIL}`} className="text-body-m text-text-grey-super-light">
          {EMAIL}
        </a>
      </Typography>
      <span className="ml-6 hidden desktop:block">
        <OpeningHours light />
      </span>
    </div>
  );
};
