import { useQuery } from '@tanstack/react-query';

import { request } from '@/network/Api';
import { QueryKeys } from '@/network/query.types';

export function useCarSearchProducers() {
  return useQuery({
    queryKey: [QueryKeys.carSearchProducers],
    queryFn: request.carSearchBarMakesList,
    staleTime: Infinity,
  });
}

export function useCarSearchModelsQuery(producer: string) {
  return useQuery({
    queryKey: [QueryKeys.carSearchModels, producer],
    queryFn: () => request.carSearchBarMakesModelsList(producer),
    staleTime: Infinity,
    enabled: !!producer,
  });
}

export function useCarSearchGenerationsQuery(producer: string, model: string, year: string) {
  return useQuery({
    queryKey: [
      QueryKeys.carSearchGenerations,
      {
        producer,
        model,
        year,
      },
    ],
    queryFn: () => request.carSearchBarMakesModelsGenerationsList(producer, model, year),
    staleTime: Infinity,
    enabled: !!producer && !!model && !!year,
  });
}

export function useCarSearchEnginesQuery(producer: string, model: string, year: string, generation: string) {
  return useQuery({
    queryKey: [
      QueryKeys.carSearchEngines,
      {
        producer,
        model,
        year,
        generation,
      },
    ],
    queryFn: () => request.carSearchBarMakesModelsGenerationsEnginesList(generation, producer, model, year),
    staleTime: Infinity,
    enabled: !!producer && !!model && !!year && !!generation,
  });
}

export function useCarSearchSizesQuery(
  producer: string,
  model: string,
  year: string,
  generation: string,
  engine: string,
) {
  return useQuery({
    queryKey: [
      QueryKeys.carSearchSizes,
      {
        producer,
        model,
        year,
        generation,
        engine,
      },
    ],
    queryFn: () =>
      request.carSearchBarMakesModelsGenerationsEnginesGetSizesRetrieve(generation, producer, model, engine, year),
    staleTime: Infinity,
    enabled: !!producer && !!model && !!year && !!generation && !!engine,
  });
}
