import { useGetCheckoutSimpleQuery } from '@/network/_queries/checkout.queries';

export const useGetCheckoutSimple = () => {
  const { data, isError } = useGetCheckoutSimpleQuery();

  if (isError || !data) {
    return {
      linesCount: 0,
      totalPrice: null,
    };
  }
  return {
    linesCount: data.lines_count,
    totalPrice: data.total_gross_amount,
  };
};
