export enum QueryKeys {
  tyreSearchBarWidths = 'tyreSearchBarWidths',
  tyreSearchBarWidthsProfiles = 'tyreSearchBarWidthsProfiles',
  tyreSearchBarWidthsProfilesRims = 'tyreSearchBarWidthsProfilesRims',
  tyreSearchBarFilters = 'tyreSearchBarFilters',
  tyreCategory = 'tyreCategory',
  tyreProduct = 'tyreProduct',
  tyreMainSearchFilters = 'tyreMainSearchFilters',
  tyresListingPage = 'tyresListingPage',
  checkout = 'checkout',
  checkoutSimple = 'checkoutSimple',
  checkoutPaymentMethods = 'checkoutPaymentMethods',
  orderSummary = 'orderSummary',
  tyresListingBanners = 'tyresListingBanners',
  tyreModel = 'tyreModel',
  mainPage = 'mainPage',
  blog = 'blog',
  blogCategory = 'blogCategory',
  blogSearch = 'blogSearch',
  blogPost = 'blogPost',
  blogPostCategory = 'blogPostCategory',
  carSearchProducers = 'carSearchProducers',
  carSearchModels = 'carSearchModels',
  carSearchGenerations = 'carSearchGenerations',
  carSearchEngines = 'carSearchEngines',
  carSearchSizes = 'carSearchSizes',
  tyresCars = 'tyresCars',
  tyresCarProducer = 'tyresCarProducer',
  tyresCarModel = 'tyresCarModel',
  tyreReviews = 'tyreReviews',
  orderLineReview = 'orderLineReview',
  user = 'user',
  session = 'session',
  validateResetPasswordToken = 'validateResetPasswordToken',
  compare = 'compare',
  accountOrders = 'accountOrders',
  accountOrderDetails = 'accountOrderDetails',
  tyreProductSales = 'tyreProductSales',
}
