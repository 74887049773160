import { createIntl, FormatNumberOptions } from 'react-intl';

const priceIntl = createIntl({
  // To keep spaces in price
  locale: 'fr-FR',
});

export const usePrice = () => {
  return (value: number | string, options?: FormatNumberOptions) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (Number.isNaN(parsedValue)) {
      return '---';
    }
    return priceIntl.formatNumber(parsedValue, {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'narrowSymbol',
      ...options,
    });
  };
};

export const usePrettyPrice = () => {
  return (value: number | string, options?: FormatNumberOptions) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (Number.isNaN(parsedValue)) {
      return '---';
    }
    return priceIntl.formatNumber(parsedValue, {
      style: 'decimal',
      minimumFractionDigits: parsedValue % 1 !== 0 ? 2 : 0,
      ...options,
    });
  };
};

export const usePriceParts = () => {
  return (value: number | string) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (Number.isNaN(parsedValue)) {
      return ['---', '---'];
    }
    const parsed = priceIntl.formatNumber(parsedValue, {
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return parsed.split(',');
  };
};
