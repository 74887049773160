import React from 'react';

import { cn } from '@/utils/styles.utils';

interface Props {
  mobile?: number;
  tablet?: number;
  laptop?: number;
  desktop?: number;
  mobileStart?: number;
  tabletStart?: number;
  laptopStart?: number;
  desktopStart?: number;
  className?: string;
  children?: React.ReactNode;
}

export const GridIem = (props: Props) => {
  const { mobile, tablet, laptop, desktop, mobileStart, tabletStart, laptopStart, desktopStart, className, children } =
    props;
  return (
    <div
      className={cn(
        {
          [`col-span-${mobile}`]: mobile,
          [`tablet:col-span-${tablet}`]: tablet,
          [`laptop:col-span-${laptop}`]: laptop,
          [`desktop:col-span-${desktop}`]: desktop,
          [`col-start-${mobileStart}`]: mobileStart !== undefined,
          [`tablet:col-start-${tabletStart}`]: tabletStart !== undefined,
          [`laptop:col-start-${laptopStart}`]: laptopStart !== undefined,
          [`desktop:col-start-${desktopStart}`]: desktopStart !== undefined,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
